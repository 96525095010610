.data-numeric .col-md-2 {
  padding: 0
}

.App .data-numeric .react-numeric-input b {
  z-index: 10;
}

.App .data-numeric .input-group-addon {
  padding: 6px 10px
}

.App .data-numeric input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px !important;
  border-top-right-radius: 4px !important;
  float: none;
}