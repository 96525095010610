.App .entity-detail-item {
  background: white;
  border-radius: 3px;
  border: 1px solid #e3e3e3;
  text-align: left;
  margin-bottom: 20px;
}
.App .entity-detail-item.no-rounding {
  border-radius: 0;
}
.App .entity-detail-item.no-bottom-margin {
  margin-bottom: 0;
}
.App .entity-detail-item .table-footer {
  padding: 9px 20px;
  border-top: 1px solid #e3e3e3;
}

.App.acp-theme-dark .entity-detail-item {
  background-color: #15202b;
  border-color: #38444d;
}
.App.acp-theme-dark .entity-detail-item .table-footer {
  border-color: #38444d;
}
