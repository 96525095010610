.FieldUploader .main {
  display: flex;
  justify-content: flex-end;
}
.FieldUploader .main .feedback,
.FieldUploader .main .uploader {
}
.FieldUploader .main .uploader {
  display: flex;
}
.FieldUploader .main .uploader a.view {
  margin-left: 4px;
}
