.employer-email-invoices-modal .form-item {
  margin-top: 10px;
}
.employer-email-invoices-modal .form-item:first-child {
  margin-top: 0;
}
.employer-email-invoices-modal .form-item .file-format {
  margin-top: 10px;
}
.employer-email-invoices-modal .form-item .file-format .radios {
  margin-left: 5px;
}
.employer-email-invoices-modal .form-item .file-format .radios label {
  font-weight: normal;
  vertical-align: inherit;
  line-height: inherit;
  cursor: pointer;
  margin-left: -2px;
  padding-left: 0;
  margin-right: 15px;
}
.employer-email-invoices-modal .form-item .file-format .radios label input {
  margin-top: 7px;
  position: static;
  margin: 0;
  cursor: pointer;
  margin-right: 5px;
}
