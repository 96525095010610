.App {
  .DataEnum {
    label {
      position: static;
      display: inline-block;
      padding: 0;
      margin: 0;
      font-weight: normal;
      vertical-align: inherit;
      line-height: inherit;
      cursor: pointer;
      margin-left: -2px;
      margin-right: 15px;

      input {
        margin-top: 7px;
        position: static;
        margin: 0;
        cursor: pointer;
        margin-right: 4px;
      }
    }
  }
}
