.WorkerHeader {
  .content-wrapper {
    display: flex;
    gap: 18px;
    align-items: center;
    padding-inline: 20px;
    padding-block: 10px;
    justify-content: space-between;
  }
}
