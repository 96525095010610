.acp-table .simpletable th div.acp-cell-text,
.acp-table .simpletable td div.acp-cell-text {
  padding: 9px 11px;
  display: block;
}

/* If the table has col groups, their contents will be wrapped in this component. */
.acp-table .simpletable th div.is-col-group {
  text-align: center;
  font-weight: 500;
  color: #1f2022;
}

/* Sometimes a footer will have multiple paragraph tags in it.
 * Make sure the last one does not have bottom margin.
 *
 * The padding of the cell is the same amount as the margin of the <p>. */
.acp-table .simpletable .acp-table-footer div.acp-cell-text p:last-child {
  margin-bottom: 0;
}
