#modals {
  .WorkingWeekInvoicingOptions {
    &__column {
      &:first-of-type {
        border-right: 1px solid #e5e5e5;
      }
    }
    &__headline {
      display: inline-flex;
      align-items: center;
      flex-direction: row-reverse;
      margin-left: -20px;
      font-size: 1.1rem;
      font-weight: 700;
      color: black;

      input {
        margin-left: 0;
        margin-right: -40px;
      }
    }
  }
}
