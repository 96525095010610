.DataExternalUUIDs .uuid-contain {
}
.DataExternalUUIDs .input-group {
  width: 300px;
}
.DataExternalUUIDs .input-group.editing {
  width: 367px;
}
.DataExternalUUIDs .existing-items {
  padding-left: 7px;
}
