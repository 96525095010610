.ScreenReaderOnlyText {
    border: 0px solid;
    clip: rect(0 0 0 0);
    height: 1;
    margin: -8px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1;
}
