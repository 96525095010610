.shift-fulfilment-list {
  &__header {
    padding: 5px;
    text-align: start;
  }
  &__field {
    width: 182px;
  }
}

.shift_fulfilment-legend {
  &__container {
    position: relative;
    float: right;
    margin-right: 15px;
  }
  &__first-shifts {
    display: inline-flex;
  }
  &__last_minute_cancelations-legend {
    background-color: #ffeded;
    width: 33px;
    height: 33px;
    border: 1px solid;
  }
  &__first-shifts-legend {
    background-color: #e3f9ff;
    width: 33px;
    height: 33px;
    border: 1px solid;
  }
  &__text {
    margin-top: -5px;
    margin-left: 5px;
  }
  &__header {
    margin-top: 2px;
    color: black;
    font-weight: bold;
  }
  &__sub-text {
    font-weight: normal;
    color: black;
  }
}
