.App span.acp-filter-container {
  display: inline-block;
  position: relative;
}
.App span.acp-filter-container + span.acp-filter-container {
  margin-left: 3px;
}
.App span.acp-filter-container.is-small {
  width: 125px;
}
.App span.acp-filter-container.is-tiny {
  width: 80px;
}
