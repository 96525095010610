.App .acp-record-footer {
  display: block;
  padding: 9px 11px;
}
.App .acp-record-footer.hasTopBorder {
  border-top: 1px solid #e3e3e3;
}
.App .acp-record-footer.hasBackground {
  border: 1px solid #e3e3e3;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background: white;
}
.App .acp-record-footer p:last-child {
  margin-bottom: 0;
}
