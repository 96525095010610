.App .entity-list-controls {
  text-align: left;
  position: relative;
}
.App .entity-list-controls .filter-group {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.App .entity-list-controls .form-group.smaller input {
  width: 135px;
}
.App .entity-list-controls .form-group.tiny input {
  width: 50px;
}
.App .entity-list-controls .button-group-label {
  min-height: 18px;
}
.App .entity-list-controls .button-group-label,
.App .entity-list-controls label.control-label {
  display: block;
  margin-bottom: 4px;
  margin-left: 2px;
  font-weight: normal;
}
.App .entity-list-controls .dropdown-menu.bootstrap-typeahead-menu.dropdown-menu-justify {
}
.App .entity-list-controls .controls-row {
  margin-bottom: 10px;
}
.App .entity-list-controls .controls-row:last-child {
  margin-bottom: 0;
}
.App .entity-list-controls .btn.active,
.App .entity-list-controls a[role='button'].active {
  position: relative;
  z-index: 20;
}
.App .entity-list-controls .btn.type-gray,
.App .entity-list-controls a[role='button'].type-gray {
  background-color: #e6ebef;
  position: relative;
  z-index: 10;
  box-shadow: inset 0 -1.5px 0 #b1b6ba, inset 0 0 0 1px #cdd4da;
}
.App .entity-list-controls .btn.type-gray:hover,
.App .entity-list-controls .btn.type-gray:focus,
.App .entity-list-controls a[role='button'].type-gray:hover,
.App .entity-list-controls a[role='button'].type-gray:hover {
  background-color: #a8afb4;
  box-shadow: inset 0 -1.5px 0 #757a7d, inset 0 0 0 1px #8e959b;
}
.App .entity-list-controls .btn.type-yellow {
  background-color: #ffffdb;
  box-shadow: inset 0 -1.5px 0 #cccc9f, inset 0 0 0 1px #eeeebb;
}
.App .entity-list-controls .btn.type-yellow:hover,
.App .entity-list-controls .btn.type-yellow:focus {
  background-color: #d9d9ac;
  box-shadow: inset 0 -1.5px 0 #9a9a61, inset 0 0 0 1px #c3c384;
}
.App .entity-list-controls .btn.type-red {
  background-color: #ffeded;
  box-shadow: inset 0 -1.5px 0 #d8b6b7, inset 0 0 0 1px #fbd4d5;
}
.App .entity-list-controls .btn.type-red:hover,
.App .entity-list-controls .btn.type-red:focus {
  background-color: #d9c2c2;
  box-shadow: inset 0 -1.5px 0 #a87d7f, inset 0 0 0 1px #d5a3a4;
}
.App .entity-list-controls .btn.type-green {
  background-color: #e5ffde;
  box-shadow: inset 0 -1.5px 0 #acd1a4, inset 0 0 0 1px #c8f3bf;
}
.App .entity-list-controls .btn.type-green:hover,
.App .entity-list-controls .btn.type-green:focus {
  background-color: #b9d9b1;
  box-shadow: inset 0 -1.5px 0 #719f66, inset 0 0 0 1px #94ca8a;
}
.App .entity-list-controls .btn.type-yellow.active {
  color: white;
  background-color: #f6c956;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), inset 0 -1px 0 rgba(0, 0, 0, 0.08);
}
.App .entity-list-controls .btn.type-gray.active {
  color: white;
  background-color: #8e9498;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), inset 0 -1px 0 rgba(0, 0, 0, 0.08);
}
.App .entity-list-controls .btn.type-yellow.active:hover {
  color: white;
  background-color: #c0a83f;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), inset 0 -1px 0 rgba(0, 0, 0, 0.08);
}
.App .entity-list-controls .btn.type-red.active {
  background: #ff5252;
  color: white;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), inset 0 -1px 0 rgba(0, 0, 0, 0.08);
}
.App .entity-list-controls .btn.type-red.active:hover {
  background: #c23e3e;
  color: white;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), inset 0 -1px 0 rgba(0, 0, 0, 0.08);
}
.App .entity-list-controls .btn.type-green.active {
  background-color: #4ccc56;
  color: white;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), inset 0 -1px 0 rgba(0, 0, 0, 0.08);
}
.App .entity-list-controls .btn.type-green.active:hover {
  color: white;
  background-color: #399a41;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), inset 0 -1px 0 rgba(0, 0, 0, 0.08);
}
.App .entity-list-controls .btn.type-green:active,
.App .entity-list-controls .btn.type-yellow:active,
.App .entity-list-controls .btn.type-green:active:hover,
.App .entity-list-controls .btn.type-green:active:focus,
.App .entity-list-controls .btn.type-red:active:hover,
.App .entity-list-controls .btn.type-red:active:focus,
.App .entity-list-controls .btn.type-yellow:active:hover,
.App .entity-list-controls .btn.type-yellow:active:focus {
  color: white;
  background-color: #a304c3;
  box-shadow: inset 0 -1.5px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px rgba(0, 0, 0, 0.2);
}

.App .entity-list-controls .dropdown-menu li.gray a,
.App .entity-list-controls .dropdown-menu li.yellow a,
.App .entity-list-controls .dropdown-menu li.red a,
.App .entity-list-controls .dropdown-menu li.green a {
  padding-left: 19px;
  padding-right: 19px;
}
.App .entity-list-controls .dropdown-menu li.gray a {
  background-color: #e6ebef;
}
.App .entity-list-controls .dropdown-menu li.yellow a {
  background-color: #ffffdb;
}
.App .entity-list-controls .dropdown-menu li.red a {
  background-color: #ffeded;
}
.App .entity-list-controls .dropdown-menu li.green a {
  background-color: #e5ffde;
}
.App .entity-list-controls .dropdown-menu li.gray a:hover {
  background-color: #8e9498;
}
.App .entity-list-controls .dropdown-menu li.yellow a:hover {
  background-color: #f6c956;
}
.App .entity-list-controls .dropdown-menu li.red a:hover {
  background-color: #ff5252;
}
.App .entity-list-controls .dropdown-menu li.green a:hover {
  background-color: #4ccc56;
}

.App .entity-list-controls .btn-group {
  vertical-align: top;
}

.App .entity-list-controls .pagination {
  margin: 0 20px 0 0;
  vertical-align: top;
}
