$lightGrey: #e3e3e3;
$greyButtonStroke: #d7d7d8;

.button {
  width: 117px;
  height: 34px;
  border-radius: 3px;
  border: solid 1px $greyButtonStroke;
  border-bottom: solid 2px $greyButtonStroke;
  appearance: none;
  background-color: white;

  &--primary {
    color: white;
    background-color: #5f83ef;
    border-color: #5879dd;
  }

  &--confirm {
    color: white;
    background-color: #6d9852;
    border-color: #5b7c45;
    font-weight: 500;
  }

  &--small {
    width: 60px;
    height: 25px;
  }

  &:disabled {
    color: white;
    background-color: $lightGrey;
    border-color: $greyButtonStroke;
  }

  &:hover,
  &:active,
  &:focus {
    border-width: 1px;
  }
}
