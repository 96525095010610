.employer-rate-card-form__title {
  font-weight: bold;
  margin: 0 0 5px;
}

.employer-rate-card-form__errors {
  margin: 0 0 0 15px;
  padding: 0
}

.employer-rate-card-form .date-picker {
  width: 195px
}

.App .employer-rate-card-form .editable-table tr td.field .component .DataText.plain-text {
  padding-left: 0;
}

.employer-rate-card-form__actions {
  display: flex;
  gap: 10px
}