.LoadingSpinner {
  display: inline-block;
  vertical-align: middle;
  width: 28px;
  height: 28px;
}
.LoadingSpinner > svg {
  opacity: 0;
  transition: opacity 0.3s linear;
}
.LoadingSpinner.isLoading > svg {
  opacity: 1;
}
