@import '../../variables';

.acp-table.has-old-data .simpletable tr .acp-table-cell-container {
  transition: color ease-in-out 1s;
  color: #757575;
}

.acp-table .simpletable tr .acp-table-cell-container {
  height: 100%;
  padding: $cellPaddingAll;
  display: block;
  color: #1f2022;
}

/* Add extra highlight color for cells that have their own link. */
.acp-table .simpletable tr .acp-table-cell-container.has-cell-link:hover {
  background-color: #ddecf4;
}

.acp-table .simpletable tr .acp-table-cell-container.is-pure {
  /* Don't impose padding for cell types that aren't text based. */
  padding: 0;
}

.acp-table .simpletable tr .acp-table-cell-container.is-form {
  /* Form elements are padded by 2px from the cell edges. */
  padding: 2px 2px;
}

.acp-table .simpletable tr .acp-table-cell-container.is-header {
  /* Header checkboxes need an absolute height set. */
  height: 33px;
}

.acp-table .simpletable tr .acp-table-cell-container.is-not-clickable {
  cursor: default;
}

.acp-table .simpletable tr .acp-table-cell-container.is-clickable {
  cursor: pointer;
}

.acp-table .simpletable tr .acp-table-cell-container.nowrap {
  white-space: nowrap;
}
