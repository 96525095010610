.App .AcpCellEnumItem.type-tag,
#modals .AcpCellEnumItem.type-tag {
  background: gray;
  display: inline;
  margin-right: 4px;
  padding: 3px 5px;
  font-size: 11px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  border-radius: 3px;
}
.App .AcpCellEnumItem.type-text,
#modals .AcpCellEnumItem.type-text {
  display: inline;
}
