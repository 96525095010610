span.filter-unit {
  display: inline-block;
  position: relative;
}
span.filter-unit + span.filter-unit {
  margin-left: 3px;
}
span.filter-unit.small {
  width: 125px;
}

span.filter-unit .dropdown-menu {
  min-width: 173px;
}

span.filter-unit.tiny .dropdown-menu {
  min-width: 50px;
}
span.filter-unit.small .dropdown-menu {
  min-width: 125px;
}
span.filter-unit.full {
  min-width: 100%;
  width: 100%;
}
