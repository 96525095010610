$lightGrey: #f3f4f5;
$greyStroke: #e5e5e5;

.shift-fulfilment-modal {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;

  &__inner {
    width: 768px;
    background-color: $lightGrey;
    border-radius: 0;
    padding: 13px 16px;
  }

  &__row {
    padding: 0px 12px;
  }

  &__spinner {
    margin-left: 480px;
  }

  &__ids_spinner {
    &--first {
      text-align: center;
      height: 337px;
    }
    &--second {
      text-align: center;
      height: 200px;
    }
    &--third {
      text-align: center;
      height: 422px;
    }
  }

  &__exit {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 6px;
  }

  &__button {
    margin-right: 13px;
  }

  &__save-button {
    width: 117px;
  }

  &__pay_rate_change__label {
    color: red;
    font-size: 10px;
    margin-left: 90px;
    margin-top: -13px;
    margin-bottom: 5px;
  }

  &__time_change__label {
    color: red;
    font-size: 10px;
    margin-left: 90px;
    margin-bottom: 5px;
  }

  &__review-button {
    width: 132px;
  }
  &__not-review-button {
    width: 160px;
    background: #ff5252;
    border-color: #c23e3e;
    color: white;
  }

  &__hard-skills {
    align-self: start;
    min-width: 80px;
    margin-top: 8px;
  }

  &__hard-skills-component {
    width: 220px;
  }

  &__offer-type {
    width: 90px;
    margin-bottom: 5px;
  }

  &__section {
    margin-bottom: 16px;
    border: solid 1px $greyStroke;
    border-radius: 3px;
    background-color: white;

    &__auditlog {
      margin-top: 10px;
    }
    &__row {
      padding: 12px 23px;
      border-bottom: solid 1px $greyStroke;

      &--spread {
        display: flex;
        justify-content: space-evenly;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &__key-value {
      flex: 1;
      margin-right: 15px;
    }
  }

  &__field {
    display: flex;
    align-items: center;
    width: 100%;

    &__label {
      width: 90px;
    }

    &__label-offer-shift {
      width: 120px;
    }
  }

  &__datepicker {
    &__label {
      width: 100px;
    }
    &__col {
      padding-right: 10px;
    }
  }

  &__hard-skills-col {
    overflow: hidden;
    a {
      align-self: baseline;
      margin-top: 5px;
    }

    span[class~='hard-skill'] {
      max-width: 215px;

      span[class~='content'] {
        display: flex;
        overflow: hidden;
      }
    }
  }

  &__checkboxes-label {
    margin-left: 10px;
    font-weight: normal;
  }

  &__radio-field {
    display: flex;
    width: 100%;

    &__label {
      width: 80px;
    }
  }

  &__radio {
    margin-top: 0px;
  }

  &__radio-item {
    margin-left: 25px;
  }

  &__radio-container {
    display: grid;
    align-items: center;
  }

  &__checkboxes-message {
    margin-left: 5px;
    font-size: 11px;
    max-width: 350px;
    font-weight: normal;
  }

  &__confirmation {
    width: 324px;
    padding: 17px;

    &__heading {
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
    }

    &__sub-heading {
      font-weight: 700;
      line-height: 150%;
    }

    &__content {
      margin-bottom: 10px;
      line-height: 150%;
    }

    &__continue-button {
      width: 91px;
    }

    &__cancel-button {
      width: 85px;
    }

    &__list {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    &__count {
      color: red;
    }
  }
}

.shift-fulfilment-modal .DataJobSkills {
  max-width: 230px;
}
