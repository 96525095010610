.type-label {
  display: inline-block;
  min-height: 16px;
  margin-right: 3px;
}
.type-label .label {
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 10px;
  position: relative;
  top: 0;
}
.type-label .label:first-child {
  margin-left: 0;
}
