.editable-textbox {
  &__input {
    margin: 0 0 10px 0;
    width: 100%;
    height: 80px;
    border-radius: 3px;
    border: solid 1px #e3e3e3;
    resize: none;
  }

  &__button {
    margin-right: 10px;
  }
  &__message {
    margin-bottom: 10px;
    font-size: 11px;
    font-weight: normal;
  }
}
