.App .entity-detail-item.no-margin {
  margin: 0;
}
.App .entity-detail-item.padded {
  padding: 8px 6px;
}
.App .entity-detail-item.with-entity-list {
}
.App .entity-detail-item.with-entity-list .entity-list {
  margin-bottom: 13px;
}

.App .entity-detail-item.with-pagination .table.entity-list {
  margin-bottom: 12px;
}

/* Remove last bottom border of entity lists; only if there is no footer. */
.App
  .entity-detail-item.with-entity-list.without-pagination
  .entity-list-wrapper.no-footer
  tbody:last-child
  tr
  td {
  margin-bottom: 10px;
}
/* Fix border radius on the last row. */
.App
  .entity-detail-item.with-entity-list.without-pagination
  .entity-list-wrapper.no-footer
  tbody:last-child
  tr
  td:first-child {
  border-bottom-left-radius: 3px;
  overflow: hidden;
}
.App
  .entity-detail-item.with-entity-list.without-pagination
  .entity-list-wrapper.no-footer
  tbody:last-child
  tr
  td:last-child {
  border-bottom-right-radius: 3px;
  overflow: hidden;
}
/* Remove footer bottom border if we have one. */
.App .entity-detail-item.with-entity-list .entity-list-wrapper.has-footer .entity-list-footer {
  border-bottom: 0;
  background: transparent;
}
/* Fix border in placeholders inside entity detail items. */
.App .entity-detail-item .entity-list-wrapper.no-footer table.entity-list tr td.placeholder {
  border-bottom: 0;
  border-right: 0;
  background: transparent;
}
/* Glue together multiple detail items with 'connected'. */
.App .entity-detail-item.connected:first-of-type {
  margin-bottom: 0;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.App .entity-detail-item.connected + .entity-detail-item.connected {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0;
  border-bottom: 0;
}
.App .entity-detail-item.connected + .entity-detail-item.connected:last-child {
  border-bottom: 1px solid #e3e3e3;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
