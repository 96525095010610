.App .editable-data-rate .react-numeric-input {
}

.App .editable-data-rate .react-numeric-input b,
#modals .editable-data-rate .react-numeric-input b {
  z-index: 10;
}
.App .editable-data-rate input,
#modals .editable-data-rate input {
  /* Note: these hacks are needed because the HTML structure is not as expected for .input-group. */
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px !important;
  border-top-right-radius: 4px !important;
  float: none;
}

.App .editable-data-rate .if-zero,
#modals .editable-data-rate .if-zero {
  display: none;
}
.App .editable-data-rate.hide-zero,
#modals .editable-data-rate.hide-zero {
  position: relative;
  z-index: 50;
}
.App .editable-data-rate.hide-zero input,
#modals .editable-data-rate.hide-zero input {
  color: transparent !important;
  background: transparent !important;
  text-shadow: none !important;
}
.App .editable-data-rate.hide-zero .if-zero,
#modals .editable-data-rate.hide-zero .if-zero {
  position: absolute;
  top: 0;
  display: block;
  padding: 6px 12px;
  margin-left: 1px;
  line-height: 22px;
  z-index: -50;
}
.App .editable-data-rate.hide-zero.above-zero .if-zero,
#modals .editable-data-rate.hide-zero.above-zero .if-zero {
  display: none;
}
.App .editable-data-rate.hide-zero.above-zero input,
#modals .editable-data-rate.hide-zero.above-zero input {
  color: #555 !important;
}

.date-rate-grid {
  margin-left: 0px;
}

.date-rate-col {
  padding: 0px;
}
