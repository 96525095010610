.missing-time-modal .form-group .row {
  margin-bottom: 4px;
}
.missing-time-modal .form-group .row .control-label {
  line-height: 34px;
  margin-bottom: 0;
}

.missing-time-modal .form-group .row .text-value {
  line-height: 34px;
  margin-bottom: 0;
  padding-left: 12px;
}
.missing-time-modal .form-group .row .DataEnum {
  line-height: 30px;
}
.missing-time-modal .form-group .row .DataEnum .radio-inline input {
  top: 4px;
}

.missing-time-modal .form-group .row .textarea-top textarea {
  margin-bottom: -1px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.missing-time-modal .form-group .row .textarea-bottom textarea {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.missing-time-modal .form-group .row textarea {
  position: relative;
}
.missing-time-modal .form-group .row textarea:focus {
  z-index: 500;
}
