.xCircleIcon {
  margin: 5px;
  border-radius: 16px;
  background: grey;
  height: 16px;
  width: 16px;
}

.xCircleIcon svg {
  margin-top: 1.5px;
  margin-left: 3px;
}
