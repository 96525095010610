.matching-explainability {
  &__table {
    width: 300px;
  }
  &__text-box {
    width: 288px;
    display: inline-flex;
    position: relative;
    margin-right: 24px;
  }
  &__container {
    padding: 21px;
  }
  &__search-button {
    margin-left: 15px;
    margin-bottom: 10px;
  }
}

.common-description {
  &__link {
    cursor: pointer;
    color: #236dab;
    font-weight: bold;
  }
  &__link:hover {
    text-decoration: underline;
  }
}

.worker-filter-results {
  &__container {
    margin-left: 10px;
    vertical-align: middle;
  }
}

.title-with-tooltip {
  &__container {
    display: inline-block;
    width: 200px;
    margin-right: 40px;
    white-space: initial;
  }
  &__tooltip {
    display: inline-block;
    vertical-align: 'middle';
    position: absolute;
    right: 20px;
  }
  &__font {
    font-size: 15px;
  }
}
