.listing-shift-workers {
  position: relative;
}

.listing-shift-workers .small-workers-list {
  height: 218px;
  overflow: auto;
}

.listing-shift-workers .alert {
  margin: 0;
}

.listing-shift-workers .alert {
  color: #e12a00;
  background: none;
  border: 1px solid rgb(227, 227, 227);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.listing-shift-workers .entity-list-controls .button-group-label {
  display: none;
}

.listing-shift-workers .padded {
  padding: 10px;
}

.type_of_workers {
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 12px;
  background-color: lightgray;
  padding: 2px 5px;
  color: black;
}
