.App .acp-cell-placeholder {
  display: block;
  position: relative;
}
.App .acp-cell-placeholder.type-AcpCellBoolean .acp-placeholder-line {
  display: none;
}
.App .acp-cell-placeholder .acp-placeholder-spacer {
  padding: 9px 11px;
}
.App .acp-cell-placeholder .acp-placeholder-line {
  position: absolute;
  left: 11px;
  top: 50%;
  margin-top: -4px;
  width: calc(100% - 22px);
  height: 8px;
}
.App .acp-cell-placeholder .acp-placeholder-line .acp-placeholder-line-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  min-width: 10px;
  height: 8px;
  background: #d9dcdf;
  border-radius: 1px;
}
.App .acp-cell-placeholder.type-group-AcpEditableCol:before {
  position: absolute;
  content: '';
  left: 3px;
  top: 3px;
  right: 3px;
  bottom: 3px;
  border: 2px solid #d9dcdf;
  border-radius: 4px;
}

@keyframes PlaceholderLoop {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 150vh 0;
  }
}

.App .acp-cell-placeholder.isLoading .acp-placeholder-line .acp-placeholder-line-inner {
  background: linear-gradient(135deg, #d9dcdf 0%, #d9dcdf 2%, #f3f4f5 4%, #d9dcdf 6%, #d9dcdf 100%);
  background-size: 150vh;
  animation: PlaceholderLoop 1s linear infinite;
}
