.acp-cell-info {
  float: left;
  width: 50px;
  margin-top: 7px;
}

.acp-cell-info-container {
  width: 0px;
  padding-left: 5px;
}
