.App .FilterAutocomplete .rbt .dropdown-menu > li > a:hover {
  color: white;
  background: #ff5252;
  box-shadow: none !important;
}
.App .FilterAutocomplete .rbt .dropdown-menu > li > a .rbt-highlight-text {
  background: rgba(255, 231, 2, 0.46);
  color: #493f06;
  line-height: 1;
  border-radius: 3px;
  padding: 3px 3px 4px;
  margin-left: -3px;
  margin-right: -3px;
}
.App .FilterAutocomplete .rbt .dropdown-menu > li > a:hover .rbt-highlight-text {
  background: #fec8cb;
  color: #4c1619;
}
.App .FilterAutocomplete .rbt .rbt-input .rbt-input-main {
  line-height: 21px;
}

.App .FilterAutocomplete.hasClearButton .rbt-input-main {
  padding-right: 20px;
}

.App .FilterAutocomplete.isSpotlightSearch .rbt-input-main {
  border: 0;
  outline: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #efeff9;
}

.App .FilterAutocomplete .rbt-aux {
  position: absolute;
  z-index: 4000;
  right: 6px;
  top: 6px;
}
