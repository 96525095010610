.editable-table {
  $color: #ff7a00;
  .enforcement {
    display: flex;
    align-items: center;
    color: $color;
    margin-top: 12px;
    .enforcement-text {
      margin-left: 5px;
    }
  }
}
