.SubNavigationBadge {
  display: inline;
  padding: 3px 7px 2px;
  line-height: 1;
  min-width: 10px;
  font-size: 11.5px !important; /* To override the 'monospace-text' size. */
  font-weight: normal;
  white-space: nowrap;
  color: black;
  background-color: #ddd;
  border-radius: 1em;
}
