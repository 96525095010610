table.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
}
table.table table.table {
  background-color: transparent;
}
table.table.black-text td {
  color: #1f2022;
}
table.table.minimal {
  width: auto;
}
table.table thead tr th,
table.table thead tr td,
table.table tbody tr th,
table.table tbody tr td,
table.table tfoot tr th,
table.table tfoot tr td {
  line-height: 1.5;
  padding: 0.45em 0.5em 0.45em 0.5em;
  border-color: #e3e3e3;
  border-style: solid;
  border-width: 0;
}
table.table thead tr th,
table.table tbody tr th,
table.table tfoot tr th {
  border-bottom-width: 1px;
  font-weight: 700;
}
table.table thead tr td,
table.table tbody tr td,
table.table tfoot tr td {
  border-bottom-width: 1px;
}
table.table.no-bottom-margin {
  margin-bottom: 0;
}
table.table.no-padding {
  padding-top: 0;
  padding-bottom: 0;
}
table.table.no-row-borders > thead > tr > th,
table.table.no-row-borders > tbody > tr > th,
table.table.no-row-borders > tfoot > tr > th,
table.table.no-row-borders > thead > tr > td,
table.table.no-row-borders > tbody > tr > td,
table.table.no-row-borders > tfoot > tr > td {
  border-bottom-width: 0;
}
table.table.top-row-border > thead > tr:first-child > th,
table.table.top-row-border > thead > tr:first-child > td,
table.table.top-row-border > tfoot > tr:first-child > th,
table.table.top-row-border > tfoot > tr:first-child > td,
table.table.top-row-border > tbody > tr:first-child > th,
table.table.top-row-border > tbody > tr:first-child > td {
  border-top-width: 1px;
}

table.table.no-last-row-border > tfoot:last-child > tr:last-child > th,
table.table.no-last-row-border > tfoot:last-child > tr:last-child > td,
table.table.no-last-row-border > tbody:last-child > tr:last-child > th,
table.table.no-last-row-border > tbody:last-child > tr:last-child > td {
  border-bottom-width: 0;
}
table.table tbody tr.selected td,
table.table tbody tr.warning td {
  background-color: #fff1ce;
}
table.table tbody tr.danger td {
  background-color: #ffecee;
}
table.table tbody tr.success td {
  background-color: #dfffdc;
}
table.table.alt-rows tbody tr:nth-child(2n + 1) th,
table.table.alt-rows tbody tr:nth-child(2n + 1) td {
  background-color: #faf7f7;
}
table.table.alt-cols tbody th:nth-child(2n + 1),
table.table.alt-cols tbody td:nth-child(2n + 1) {
  background-color: #faf7f7;
}
table.table.hover tbody tr:hover td {
  background-color: #faf7f7;
}
table.table.hover tbody tr:hover.selected td,
table.table.hover tbody tr:hover.warning td {
  background-color: #edc537;
}
table.table.hover tbody tr:hover.danger td {
  background-color: #ee5d59;
}
table.table.hover tbody tr:hover.success td {
  background-color: #52d356;
}
table.table.col-borders th,
table.table.col-borders td {
  border-right: 1px solid #e3e3e3;
}
table.table.no-right-border th:last-child,
table.table.no-right-border td:last-child {
  border-right: none;
}
table.table.colgroup-borders {
  border-collapse: collapse !important;
}
table.table.colgroup-borders colgroup {
  border-right: 1px solid #e3e3e3;
}
table.table.colgroup-borders.col-borders colgroup {
  border-right-width: 1px;
}
table.table.bordered {
  border-collapse: separate;
}
table.table.bordered thead:first-child tr:first-child th:first-child,
table.table.bordered thead:first-child tr:first-child td:first-child,
table.table.bordered tbody:first-child tr:first-child th:first-child,
table.table.bordered tbody:first-child tr:first-child td:first-child,
table.table.bordered tfoot:first-child tr:first-child th:first-child,
table.table.bordered tfoot:first-child tr:first-child td:first-child,
table.table.bordered caption + thead tr:first-child th:first-child,
table.table.bordered caption + thead tr:first-child td:first-child,
table.table.bordered caption + tbody tr:first-child th:first-child,
table.table.bordered caption + tbody tr:first-child td:first-child,
table.table.bordered caption + tfoot tr:first-child th:first-child,
table.table.bordered caption + tfoot tr:first-child td:first-child,
table.table.bordered colgroup + thead tr:first-child th:first-child,
table.table.bordered colgroup + thead tr:first-child td:first-child,
table.table.bordered colgroup + tbody tr:first-child th:first-child,
table.table.bordered colgroup + tbody tr:first-child td:first-child,
table.table.bordered colgroup + tfoot tr:first-child th:first-child,
table.table.bordered colgroup + tfoot tr:first-child td:first-child {
  border-radius: 0;
  border-top-left-radius: 3px;
}
table.table.bordered thead:first-child tr:first-child th:last-child,
table.table.bordered thead:first-child tr:first-child td:last-child,
table.table.bordered tbody:first-child tr:first-child th:last-child,
table.table.bordered tbody:first-child tr:first-child td:last-child,
table.table.bordered tfoot:first-child tr:first-child th:last-child,
table.table.bordered tfoot:first-child tr:first-child td:last-child,
table.table.bordered caption + thead tr:first-child th:last-child,
table.table.bordered caption + thead tr:first-child td:last-child,
table.table.bordered caption + tbody tr:first-child th:last-child,
table.table.bordered caption + tbody tr:first-child td:last-child,
table.table.bordered caption + tfoot tr:first-child th:last-child,
table.table.bordered caption + tfoot tr:first-child td:last-child,
table.table.bordered colgroup + thead tr:first-child th:last-child,
table.table.bordered colgroup + thead tr:first-child td:last-child,
table.table.bordered colgroup + tbody tr:first-child th:last-child,
table.table.bordered colgroup + tbody tr:first-child td:last-child,
table.table.bordered colgroup + tfoot tr:first-child th:last-child,
table.table.bordered colgroup + tfoot tr:first-child td:last-child {
  border-radius: 0;
  border-top-right-radius: 3px;
}
table.table.bordered thead:last-child tr:last-child th:first-child,
table.table.bordered thead:last-child tr:last-child td:first-child,
table.table.bordered tbody:last-child tr:last-child th:first-child,
table.table.bordered tbody:last-child tr:last-child td:first-child,
table.table.bordered tfoot:last-child tr:last-child th:first-child,
table.table.bordered tfoot:last-child tr:last-child td:first-child {
  border-radius: 0;
  border-bottom-left-radius: 3px;
}
table.table.bordered thead:last-child tr:last-child th:last-child,
table.table.bordered thead:last-child tr:last-child td:last-child,
table.table.bordered tbody:last-child tr:last-child th:last-child,
table.table.bordered tbody:last-child tr:last-child td:last-child,
table.table.bordered tfoot:last-child tr:last-child th:last-child,
table.table.bordered tfoot:last-child tr:last-child td:last-child {
  border-radius: 0;
  border-bottom-right-radius: 3px;
}
table.table.bordered thead:first-child tr:first-child th,
table.table.bordered thead:first-child tr:first-child td,
table.table.bordered tbody:first-child tr:first-child th,
table.table.bordered tbody:first-child tr:first-child td,
table.table.bordered tfoot:first-child tr:first-child th,
table.table.bordered tfoot:first-child tr:first-child td,
table.table.bordered caption + thead tr:first-child th,
table.table.bordered caption + thead tr:first-child td,
table.table.bordered caption + tbody tr:first-child th,
table.table.bordered caption + tbody tr:first-child td,
table.table.bordered caption + tfoot tr:first-child th,
table.table.bordered caption + tfoot tr:first-child td,
table.table.bordered colgroup + thead tr:first-child th,
table.table.bordered colgroup + thead tr:first-child td,
table.table.bordered colgroup + tbody tr:first-child th,
table.table.bordered colgroup + tbody tr:first-child td,
table.table.bordered colgroup + tfoot tr:first-child th,
table.table.bordered colgroup + tfoot tr:first-child td {
  border-top: 1px solid #e3e3e3;
}
table.table.bordered thead th:first-child,
table.table.bordered thead td:first-child,
table.table.bordered tbody th:first-child,
table.table.bordered tbody td:first-child,
table.table.bordered tfoot th:first-child,
table.table.bordered tfoot td:first-child {
  border-left: 1px solid #e3e3e3;
}
table.table.bordered thead th:last-child,
table.table.bordered thead td:last-child,
table.table.bordered tbody th:last-child,
table.table.bordered tbody td:last-child,
table.table.bordered tfoot th:last-child,
table.table.bordered tfoot td:last-child {
  border-right: 1px solid #e3e3e3;
}
table.table.bordered thead:last-child tr:last-child th,
table.table.bordered thead:last-child tr:last-child td,
table.table.bordered tbody:last-child tr:last-child th,
table.table.bordered tbody:last-child tr:last-child td,
table.table.bordered tfoot:last-child tr:last-child th,
table.table.bordered tfoot:last-child tr:last-child td {
  border-bottom: 1px solid #e3e3e3;
}

.App.acp-theme-dark table.table.bordered thead:first-child tr:first-child th,
.App.acp-theme-dark table.table.bordered thead:first-child tr:first-child td,
.App.acp-theme-dark table.table.bordered tbody:first-child tr:first-child th,
.App.acp-theme-dark table.table.bordered tbody:first-child tr:first-child td,
.App.acp-theme-dark table.table.bordered tfoot:first-child tr:first-child th,
.App.acp-theme-dark table.table.bordered tfoot:first-child tr:first-child td,
.App.acp-theme-dark table.table.bordered caption + thead tr:first-child th,
.App.acp-theme-dark table.table.bordered caption + thead tr:first-child td,
.App.acp-theme-dark table.table.bordered caption + tbody tr:first-child th,
.App.acp-theme-dark table.table.bordered caption + tbody tr:first-child td,
.App.acp-theme-dark table.table.bordered caption + tfoot tr:first-child th,
.App.acp-theme-dark table.table.bordered caption + tfoot tr:first-child td,
.App.acp-theme-dark table.table.bordered colgroup + thead tr:first-child th,
.App.acp-theme-dark table.table.bordered colgroup + thead tr:first-child td,
.App.acp-theme-dark table.table.bordered colgroup + tbody tr:first-child th,
.App.acp-theme-dark table.table.bordered colgroup + tbody tr:first-child td,
.App.acp-theme-dark table.table.bordered colgroup + tfoot tr:first-child th,
.App.acp-theme-dark table.table.bordered colgroup + tfoot tr:first-child td {
  border-top: 1px solid #39444d;
}
.App.acp-theme-dark table.table.bordered thead th:first-child,
.App.acp-theme-dark table.table.bordered thead td:first-child,
.App.acp-theme-dark table.table.bordered tbody th:first-child,
.App.acp-theme-dark table.table.bordered tbody td:first-child,
.App.acp-theme-dark table.table.bordered tfoot th:first-child,
.App.acp-theme-dark table.table.bordered tfoot td:first-child {
  border-left: 1px solid #39444d;
}
.App.acp-theme-dark table.table.bordered thead th:last-child,
.App.acp-theme-dark table.table.bordered thead td:last-child,
.App.acp-theme-dark table.table.bordered tbody th:last-child,
.App.acp-theme-dark table.table.bordered tbody td:last-child,
.App.acp-theme-dark table.table.bordered tfoot th:last-child,
.App.acp-theme-dark table.table.bordered tfoot td:last-child {
  border-right: 1px solid #39444d;
}
.App.acp-theme-dark table.table.bordered thead:last-child tr:last-child th,
.App.acp-theme-dark table.table.bordered thead:last-child tr:last-child td,
.App.acp-theme-dark table.table.bordered tbody:last-child tr:last-child th,
.App.acp-theme-dark table.table.bordered tbody:last-child tr:last-child td,
.App.acp-theme-dark table.table.bordered tfoot:last-child tr:last-child th,
.App.acp-theme-dark table.table.bordered tfoot:last-child tr:last-child td {
  border-bottom: 1px solid #39444d;
}

table.table.rounded {
  border-collapse: separate;
}
table.table.rounded thead:first-child tr:first-child th:first-child,
table.table.rounded thead:first-child tr:first-child td:first-child,
table.table.rounded tbody:first-child tr:first-child th:first-child,
table.table.rounded tbody:first-child tr:first-child td:first-child,
table.table.rounded tfoot:first-child tr:first-child th:first-child,
table.table.rounded tfoot:first-child tr:first-child td:first-child,
table.table.rounded caption + thead tr:first-child th:first-child,
table.table.rounded caption + thead tr:first-child td:first-child,
table.table.rounded caption + tbody tr:first-child th:first-child,
table.table.rounded caption + tbody tr:first-child td:first-child,
table.table.rounded caption + tfoot tr:first-child th:first-child,
table.table.rounded caption + tfoot tr:first-child td:first-child,
table.table.rounded colgroup + thead tr:first-child th:first-child,
table.table.rounded colgroup + thead tr:first-child td:first-child,
table.table.rounded colgroup + tbody tr:first-child th:first-child,
table.table.rounded colgroup + tbody tr:first-child td:first-child,
table.table.rounded colgroup + tfoot tr:first-child th:first-child,
table.table.rounded colgroup + tfoot tr:first-child td:first-child {
  border-radius: 0;
  border-top-left-radius: 3px;
}
table.table.rounded thead:first-child tr:first-child th:last-child,
table.table.rounded thead:first-child tr:first-child td:last-child,
table.table.rounded tbody:first-child tr:first-child th:last-child,
table.table.rounded tbody:first-child tr:first-child td:last-child,
table.table.rounded tfoot:first-child tr:first-child th:last-child,
table.table.rounded tfoot:first-child tr:first-child td:last-child,
table.table.rounded caption + thead tr:first-child th:last-child,
table.table.rounded caption + thead tr:first-child td:last-child,
table.table.rounded caption + tbody tr:first-child th:last-child,
table.table.rounded caption + tbody tr:first-child td:last-child,
table.table.rounded caption + tfoot tr:first-child th:last-child,
table.table.rounded caption + tfoot tr:first-child td:last-child,
table.table.rounded colgroup + thead tr:first-child th:last-child,
table.table.rounded colgroup + thead tr:first-child td:last-child,
table.table.rounded colgroup + tbody tr:first-child th:last-child,
table.table.rounded colgroup + tbody tr:first-child td:last-child,
table.table.rounded colgroup + tfoot tr:first-child th:last-child,
table.table.rounded colgroup + tfoot tr:first-child td:last-child {
  border-radius: 0;
  border-top-right-radius: 3px;
}
table.table.rounded thead:last-child tr:last-child th:first-child,
table.table.rounded thead:last-child tr:last-child td:first-child,
table.table.rounded tbody:last-child tr:last-child th:first-child,
table.table.rounded tbody:last-child tr:last-child td:first-child,
table.table.rounded tfoot:last-child tr:last-child th:first-child,
table.table.rounded tfoot:last-child tr:last-child td:first-child {
  border-radius: 0;
  border-bottom-left-radius: 3px;
}
table.table.rounded thead:last-child tr:last-child th:last-child,
table.table.rounded thead:last-child tr:last-child td:last-child,
table.table.rounded tbody:last-child tr:last-child th:last-child,
table.table.rounded tbody:last-child tr:last-child td:last-child,
table.table.rounded tfoot:last-child tr:last-child th:last-child,
table.table.rounded tfoot:last-child tr:last-child td:last-child {
  border-radius: 0;
  border-bottom-right-radius: 3px;
}
table.table.rounded thead:last-child tr:last-child th:first-child:last-child,
table.table.rounded thead:last-child tr:last-child td:first-child:last-child,
table.table.rounded tbody:last-child tr:last-child th:first-child:last-child,
table.table.rounded tbody:last-child tr:last-child td:first-child:last-child,
table.table.rounded tfoot:last-child tr:last-child th:first-child:last-child,
table.table.rounded tfoot:last-child tr:last-child td:first-child:last-child {
  border-radius: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.table-responsive {
  border-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  border: 1px solid #e3e3e3;
}
.table-responsive table.table {
  margin: -1px 0;
}
.table-responsive table.table thead th,
.table-responsive table.table thead td,
.table-responsive table.table tbody th,
.table-responsive table.table tbody td,
.table-responsive table.table tfoot th,
.table-responsive table.table tfoot td {
  white-space: nowrap;
}
.table-responsive table.table thead th:first-child,
.table-responsive table.table thead td:first-child,
.table-responsive table.table tbody th:first-child,
.table-responsive table.table tbody td:first-child,
.table-responsive table.table tfoot th:first-child,
.table-responsive table.table tfoot td:first-child {
  border-left: none;
}
.table-responsive table.table thead th:last-child,
.table-responsive table.table thead td:last-child,
.table-responsive table.table tbody th:last-child,
.table-responsive table.table tbody td:last-child,
.table-responsive table.table tfoot th:last-child,
.table-responsive table.table tfoot td:last-child {
  border-right: none;
}

.App.acp-theme-dark table.table.col-borders th,
.App.acp-theme-dark table.table.col-borders td,
.App.acp-theme-dark table.table thead tr th,
.App.acp-theme-dark table.table thead tr td,
.App.acp-theme-dark table.table tbody tr th,
.App.acp-theme-dark table.table tbody tr td,
.App.acp-theme-dark table.table tfoot tr th,
.App.acp-theme-dark table.table tfoot tr td,
.App.acp-theme-dark table.table.colgroup-borders colgroup,
.App.acp-theme-dark table.table.bordered thead:first-child tr:first-child th,
.App.acp-theme-dark table.table.bordered thead:first-child tr:first-child td,
.App.acp-theme-dark table.table.bordered tbody:first-child tr:first-child th,
.App.acp-theme-dark table.table.bordered tbody:first-child tr:first-child td,
.App.acp-theme-dark table.table.bordered tfoot:first-child tr:first-child th,
.App.acp-theme-dark table.table.bordered tfoot:first-child tr:first-child td,
.App.acp-theme-dark table.table.bordered caption + thead tr:first-child th,
.App.acp-theme-dark table.table.bordered caption + thead tr:first-child td,
.App.acp-theme-dark table.table.bordered caption + tbody tr:first-child th,
.App.acp-theme-dark table.table.bordered caption + tbody tr:first-child td,
.App.acp-theme-dark table.table.bordered caption + tfoot tr:first-child th,
.App.acp-theme-dark table.table.bordered caption + tfoot tr:first-child td,
.App.acp-theme-dark table.table.bordered colgroup + thead tr:first-child th,
.App.acp-theme-dark table.table.bordered colgroup + thead tr:first-child td,
.App.acp-theme-dark table.table.bordered colgroup + tbody tr:first-child th,
.App.acp-theme-dark table.table.bordered colgroup + tbody tr:first-child td,
.App.acp-theme-dark table.table.bordered colgroup + tfoot tr:first-child th,
.App.acp-theme-dark table.table.bordered colgroup + tfoot tr:first-child td,
.App.acp-theme-dark table.table.bordered thead th:first-child,
.App.acp-theme-dark table.table.bordered thead td:first-child,
.App.acp-theme-dark table.table.bordered tbody th:first-child,
.App.acp-theme-dark table.table.bordered tbody td:first-child,
.App.acp-theme-dark table.table.bordered tfoot th:first-child,
.App.acp-theme-dark table.table.bordered tfoot td:first-child,
.App.acp-theme-dark table.table.bordered thead th:last-child,
.App.acp-theme-dark table.table.bordered thead td:last-child,
.App.acp-theme-dark table.table.bordered tbody th:last-child,
.App.acp-theme-dark table.table.bordered tbody td:last-child,
.App.acp-theme-dark table.table.bordered tfoot th:last-child,
.App.acp-theme-dark table.table.bordered tfoot td:last-child,
.App.acp-theme-dark table.table.bordered thead:last-child tr:last-child th,
.App.acp-theme-dark table.table.bordered thead:last-child tr:last-child td,
.App.acp-theme-dark table.table.bordered tbody:last-child tr:last-child th,
.App.acp-theme-dark table.table.bordered tbody:last-child tr:last-child td,
.App.acp-theme-dark table.table.bordered tfoot:last-child tr:last-child th,
.App.acp-theme-dark table.table.bordered tfoot:last-child tr:last-child td,
.App.acp-theme-dark .table-responsive {
  border-color: #38444d;
}

.rate-dependencies-table {
  display: table;
  width: 100%;
  table-layout: fixed;
}
