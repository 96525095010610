.end-assignment {
  &__message {
    margin-left: 5px;
    margin-top: 5px;
    font-size: 11px;
    font-weight: normal;
  }
  &__header {
    font-size: 13px;
  }
  &__eligible {
    margin-left: 10px;
    margin-top: 1px;
  }
  &__ban {
    margin-left: 5px;
  }
}
