.date-picker.boxed-input.auto {
  background-color: #faffbe;
}
.date-picker.boxed-input {
  max-width: 215px;
}

.date-picker-wrapper {
  display: flex;
  align-items: center;  
  flex-direction: row;
}

.date-picker-message {
  display: flex;
  flex-direction: row;  
  align-items: center;
  color: red;
  margin-left: 12px;
}

.date-picker-message-icon {
  color: 'red';
  fill: red;
  margin-right: 5px;
}