.sales-forms {
  display: flex;
}
.sales-form {
  flex-basis: 100%;
}
.sales-form .form-row {
  display: flex;
  margin-bottom: 2px;
}
.sales-form:first-child .form-row {
  margin-right: 10px;
}
.sales-form .form-row .form-label {
  line-height: 33px;
  flex-basis: 200px;
}
.sales-form .form-row .form-input {
  flex-basis: 50%;
}
.sales-form .form-row .form-input .acp-input-text-group {
  width: 210px;
}
.sales-lists {
  display: flex;
  margin-top: 30px;
}
.sales-lists .sales-list-item {
  flex-basis: 100%;
}
.sales-lists .sales-list-separator {
  flex-basis: 10px;
  min-width: 10px;
}
