.App {
  .DataMultiAssociativeEnum {
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;

    &__row {
      flex: 0 0 100%;
      display: flex;
    }
    &__column {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &__divider {
      width: 100%;
    }
  }
}
