/* Modified from https://github.com/Codebrahma/react-multi-level-selector */
.App .FilterSelectItem.small {
  max-width: 155px;
}
.App .FilterSelectItem.tiny {
  max-width: 100px;
}

.multi-level-selector-container {
  box-sizing: border-box;
  width: 100%;
  max-width: 430px;
}
.multi-level-selector-container .menu-close {
  display: none;
}
.multi-level-selector-container .menu-open {
  display: block;
}
.multi-level-selector-container .active {
  box-shadow: 0px 0px 0px 1px #2196f3;
}
.multi-level-selector-container .inactive {
  box-shadow: none;
}
.multi-level-selector-container .multi-selector-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  max-width: 100%;
  background-color: #fff;
}
.multi-level-selector-container .multi-selector-container .multi-selector {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto !important;
}
.multi-selector::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  border-start-end-radius: 4px;
  background-color: #aaa;
}
.multi-selector::-webkit-scrollbar-thumb {
  background: #000;
  border-start-end-radius: 4px;
}
.multi-level-selector-container .multi-selector-container .multi-selector .multi-selector-placeholder {
  font-size: 14px;
  color: #818181;
  margin-left: 10px;
}
.multi-level-selector-container .multi-selector-container .multi-selector .options-selected-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0px 5px 5px;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  background-color: #f0f0f0;
  min-width: max-content;
  flex-wrap: nowrap;
  height: 25px;
}
.multi-level-selector-container .multi-selector-container .multi-selector .options-selected-container .options-value {
  display: inline-block;
}
.multi-level-selector-container .multi-selector-container .multi-selector .options-selected-container .options-group {
  color: #000;
  font-size: 14px;
  font-weight: bold;
}
.multi-level-selector-container .multi-selector-container .multi-selector .options-selected-container .or-separator {
  color: #555;
  display: inline;
}
.multi-level-selector-container .multi-selector-container .multi-selector .options-selected-container .remove-group {
  color: #ccc;
  font-size: 16px;
  margin-left: 3px;
  cursor: pointer;
}
.multi-level-selector-container
  .multi-selector-container
  .multi-selector
  .options-selected-container
  .remove-group:hover {
  color: #f00;
}
.multi-level-selector-container .multi-selector-container .multi-selector-button {
  display: flex;
  align-items: center;
  margin: 5px;
  min-width: 25px;
  height: 25px;
}
.multi-level-selector-container .multi-selector-container .multi-selector-button:hover .arrow-down {
  border-top: 10px solid #555;
}
.multi-level-selector-container .multi-selector-container .multi-selector-button:hover .arrow-up {
  border-bottom: 10px solid #555;
}
.multi-level-selector-container .multi-selector-container .multi-selector-button .arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ccc;
}
.multi-level-selector-container .multi-selector-container .multi-selector-button .arrow-down:hover {
  border-top: 10px solid #555;
}
.multi-level-selector-container .multi-selector-container .multi-selector-button .arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #555;
}
.multi-level-selector-container .multi-level-options-container {
  position: absolute;
  background-color: #fff;
  margin-top: 5px;
  border-radius: 5px;
  z-index: 9999;
  box-shadow: 0px 0px 5px #ccc;
  min-width: 200px;
}

.multi-level-selector-container .multi-level-options-container.scrollable {
  max-height: 320px;
  overflow: auto;
}

.multi-level-selector-container .multi-level-options-container .options-main-menu {
  position: relative;
  margin: 0;
  padding: 0;
}
.multi-level-selector-container .multi-level-options-container .options-main-menu .options-sub-menu {
  display: flex;
  align-items: center;
  padding: 10px 25px 10px 15px;
  cursor: pointer;
}
.multi-level-selector-container .multi-level-options-container .options-main-menu .options-sub-menu:hover {
  background-color: #f0f0f0;
}
.multi-level-selector-container .multi-level-options-container .options-main-menu .options-sub-menu input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.multi-level-selector-container
  .multi-level-options-container
  .options-main-menu
  .options-sub-menu
  input:checked
  ~ .checkbox
  .checkmark {
  background-color: #2196f3;
}
.multi-level-selector-container
  .multi-level-options-container
  .options-main-menu
  .options-sub-menu
  input:checked
  ~ .checkbox
  .checkmark:after {
  display: block;
}
.multi-level-selector-container .multi-level-options-container .options-main-menu .options-sub-menu .checkbox {
  position: relative;
  height: 14px;
  width: 14px;
  margin-right: 10px;
}
.multi-level-selector-container
  .multi-level-options-container
  .options-main-menu
  .options-sub-menu
  .checkbox
  .checkmark {
  position: absolute;
  height: 14px;
  width: 14px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
}
.multi-level-selector-container
  .multi-level-options-container
  .options-main-menu
  .options-sub-menu
  .checkbox
  .checkmark:after {
  content: '';
  position: absolute;
  display: none;
  left: 5px;
  top: 1.5px;
  width: 2px;
  height: 7px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.multi-level-selector-container
  .multi-level-options-container
  .options-main-menu
  .options-sub-menu:hover
  input
  ~ .checkbox
  .checkmark {
  background-color: #fff;
}
.multi-level-selector-container
  .multi-level-options-container
  .options-main-menu
  .options-sub-menu:hover
  input:checked
  ~ .checkbox
  .checkmark {
  background-color: #2196f3;
}
.multi-level-selector-container .multi-level-options-container .options-main-menu .options-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 8px 10px 18px;
  position: relative;
}
.multi-level-selector-container .multi-level-options-container .options-main-menu .options-container .options-label {
  max-width: 180px;
  word-break: break-all;
  padding-right: 10px;
}
.multi-level-selector-container
  .multi-level-options-container
  .options-main-menu
  .options-container
  .options-sub-menu-container {
  background-color: #fff;
  box-shadow: 0px 0px 3px #ccc;
  border-radius: 5px;
  position: absolute;
  width: max-content;
  top: 0;
  right: 0;
  z-index: 9999;
  transform: translate(100%, 0);
  display: none;
  width: 220px;
  height: 300px;
  overflow-y: scroll;
}
.options-sub-menu-container-highlighted {
  background-color: #f0f8ff;
}
.multi-level-selector-container
  .multi-level-options-container
  .options-main-menu
  .options-container
  .options-sub-menu-container
  .options-sub-menu-header {
  font-size: 14px;
  padding: 10px 15px;
  color: #818181;
  text-transform: uppercase;
}
.multi-level-selector-container .multi-level-options-container .options-main-menu .options-container:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}
.multi-level-selector-container
  .multi-level-options-container
  .options-main-menu
  .options-container:hover
  > .options-sub-menu-container {
  display: block;
}
.multi-level-selector-container
  .multi-level-options-container
  .options-main-menu
  .options-container:hover
  .arrow-right {
  border-left: 7px solid #555;
}
.multi-level-selector-container .multi-level-options-container .options-main-menu .options-container .arrow-right {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #ccc;
}
.multi-level-selector-container
  .multi-level-options-container
  .options-main-menu
  .options-container
  .arrow-right:hover {
  border-left: 7px solid #555;
}
