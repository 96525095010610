.shift_worker_changes_container {
  display: flex;
  margin-top: 13px;
}

.shift_worker_changes_icon {
  display: flex;
}

.shift_worker_changes_text {
  display: flex;
  margin-left: 5px;
}

.shift_worker_changes_list {
  margin-left: -20px;
}
