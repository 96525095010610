.App .UserAvatar,
#modals .UserAvatar {
  position: relative;
  width: 50px;
  height: 50px;
  margin-left: 0;
  float: left;
}
.App .avatar-wrapper,
#modals .avatar-wrapper {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
}
.App .avatar-wrapper.inline,
#modals .avatar-wrapper.inline {
  float: none;
}
.App .avatar-wrapper img,
.App .avatar-wrapper .border,
#modals .avatar-wrapper img,
#modals .avatar-wrapper .border {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 5;
}
.App .UserAvatar .verified,
#modals .UserAvatar .verified {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #2ecaff;
  z-index: 50;
  line-height: 18px;
  right: -2px;
  text-align: center;
}
.App .UserAvatar.shape-rounded-square .verified,
#modals .UserAvatar.shape-rounded-square .verified {
  right: -8px;
  top: -4px;
}
.App .verified .icon,
#modals .verified .icon {
  fill: white;
  filter: drop-shadow(0px 1px 0px #029fd3);
}
.App .avatar-wrapper .border,
#modals .avatar-wrapper .border {
  z-index: 6;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
}
.App .UserAvatar.shape-rounded-square .avatar-wrapper,
.App .UserAvatar.shape-rounded-square .avatar-wrapper .border,
.App .UserAvatar.shape-rounded-square .avatar-wrapper img,
#modals .UserAvatar.shape-rounded-square .avatar-wrapper,
#modals .UserAvatar.shape-rounded-square .avatar-wrapper .border,
#modals .UserAvatar.shape-rounded-square .avatar-wrapper img {
  border-radius: 3px;
}
.App .UserAvatar.shape-round .avatar-wrapper,
.App .UserAvatar.shape-round .avatar-wrapper .border,
.App .UserAvatar.shape-round .avatar-wrapper img,
#modals .UserAvatar.shape-round .avatar-wrapper,
#modals .UserAvatar.shape-round .avatar-wrapper .border,
#modals .UserAvatar.shape-round .avatar-wrapper img {
  border-radius: 50%;
}
.App .UserAvatar.size-small,
.App .UserAvatar.size-small .avatar-wrapper,
#modals .UserAvatar.size-small,
#modals .UserAvatar.size-small .avatar-wrapper {
  width: 33px;
  height: 33px;
}

.App .UserAvatar.size-xsmall,
.App .UserAvatar.size-xsmall .avatar-wrapper,
#modals .UserAvatar.size-xsmall,
#modals .UserAvatar.size-xsmall .avatar-wrapper {
  width: 23px;
  height: 23px;
  margin-right: 3px;
  position: relative;
  top: -1px;
  left: -1px;
}

.App .UserAvatar.size-xsmall .avatar-wrapper .border,
#modals .UserAvatar.size-xsmall .avatar-wrapper .border {
  box-shadow: none;
}

.App.acp-theme-dark .avatar-wrapper .border,
#modals.acp-theme-dark .avatar-wrapper .border {
  box-shadow: inset 0px 0px 0px 1px #38444d;
}
.App.acp-theme-dark .avatar-wrapper img[src^='/static/media/guest'],
#modals.acp-theme-dark .avatar-wrapper img[src^='/static/media/guest'] {
  filter: invert(1);
}
