.DataIndustries .industry {
  background: gray;
  color: white;
  display: inline-block;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  line-height: 19px;
  margin-right: 3px;
  text-align: center;
  font-size: 12px;
}
.DataIndustries .industry.type-hospitality {
  background: #34a1ba;
}
.DataIndustries .industry.type-industrial {
  background: #fd7038;
}
.DataIndustries .industry.type-facilities_management {
  background: #9b46b9;
}
/* For the next one.
.DataIndustries .industry.type-facilities_management {
  background: #52d433;
}
*/
