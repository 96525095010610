.App .text-page {
  color: #2a2b2e;
  font-size: 13px;
  line-height: 22px;
}
.App .text-page.text-container {
  background: white;
  border-radius: 3px;
  border: 1px solid #e3e3e3;
  padding: 25px;
}
.App.acp-theme-dark .text-page {
  color: white;
}
.App.acp-theme-dark .text-page.text-container {
  background: #161f2a;
  border: 1px solid #3a444d;
}
.App .text-page h1,
.App .text-page h2 {
  border-bottom: 1px solid #e3e3e3;
}
.App.acp-theme-dark .text-page h1,
.App.acp-theme-dark .text-page h2 {
  border-bottom: 1px solid #3a444d;
}
.App .text-page h1,
.App .text-page h2,
.App .text-page h3,
.App .text-page h4,
.App .text-page h5,
.App .text-page h6 {
  margin: 18px 0 9px;
}
.App .text-page h1 {
  padding-bottom: 5px;
  font-size: 30px;
  line-height: 40px;
  font-weight: 300;
}
.App .text-page h1:first-child {
  margin-top: 0;
}
.App .text-page h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}
.App .text-page h3 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
}
.App .text-page h4 {
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
}
.App .text-page code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 3px;
  padding: 3px 4px 2px;
}
.App .text-page ul {
  padding-left: 2em;
}
.App .text-page *:first-child {
  margin-top: 0;
}
.App .text-page *:last-child {
  margin-bottom: 0;
}
.App .text-page dl {
}
.App .text-page dt {
}
.App .text-page dd {
  margin-left: 1em;
  margin-bottom: 8px;
}

.App.acp-theme-dark .text-page code {
  color: #c5ccd3;
  background-color: #2a3a4d;
}
