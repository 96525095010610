.acp-record-header-form-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  .acp-record-header-control-group {
    flex-basis: 100%;
  }

  .acp-item-header {
    position: relative;
    flex-basis: 100%;
  }

  .acp-record-header-form {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    flex: 100%;

    .acp-record-header-filters {
      flex-basis: 100%;
      text-align: left;
      display: block;
      width: 100%;
    }

    .acp-list-popover-button {
      margin-right: 13px;
    }

    .acp-record-header-actions {
      line-height: 28px;
      flex-basis: 0;
      white-space: nowrap;
      align-self: flex-end;
    }
    .acp-list-popover-container {
      align-self: flex-start;
    }
  }

  .is-header .acp-record-header-form {
    padding: 6px 7px 0 0;

    .acp-record-header-actions {
      align-self: flex-start;
    }
  }

  .acp-record-header-form .acp-record-header-filters .filter-component-wrapper {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .acp-record-header-form .acp-record-header-actions .action-component-wrapper {
    display: inline-block;
    vertical-align: top;
  }

  &--actions-inline {
    flex-wrap: initial;

    .acp-record-header-form {
      padding: 6px 7px 0 0;
    }
  }
}
