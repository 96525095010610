.App .acp-notifier-inline {
  color: #757575;
}
.App .acp-notifier-inline .inner {
  display: flex;
  justify-content: center;
}
.App .acp-notifier-inline .inner .section {
  flex: 0;
  line-height: 37px;
}
.App .acp-notifier-inline .inner .section.message {
  white-space: nowrap;
  margin-right: 15px;
}
/* FIXME: why 35? */
.App .acp-notifier-inline .inner .section.retry {
  line-height: 35px;
}
