.DataPermissions {
  width: 100%;
  overflow-x: auto;
}
.DataPermissions table {
  width: 100%;
  table-layout: fixed;
}
.DataPermissions .table tbody th:first-child,
.DataPermissions .table tbody td:first-child {
  padding-left: 13px;
}
.DataPermissions .table tbody th,
.DataPermissions .table tbody td {
  white-space: nowrap;
  width: 33%;
}
.DataPermissions .permissions-container {
  display: flex;
  flex-wrap: wrap;
}
.DataPermissions .permissions-container button {
  margin-right: 0.5em;
  margin-bottom: 0.5em;
}

.DataPermissions .FilterBoolCheckboxButton {
  width: 100%;
  text-align: left;
}
.DataPermissions .table tbody td .text {
  padding-top: 3px;
  display: inline-block;
  vertical-align: middle;
}
.DataPermissions .table tbody td.venue-link {
  padding: 0;
  line-height: 31px;
}
.DataPermissions .table tbody td.venue-link .link {
  padding: 0.45em 0.5em 0.45em 0.5em;
  width: 100%;
  height: 100%;
  display: block;
}
.DataPermissions .table tbody td.venue-id {
  width: 1%;
}
