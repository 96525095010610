.ListingShiftBookingsList .booking-break {
  width: 80px;
}
.ListingShiftBookingsList .booking-worker {
  min-width: 195px;
  width: 195px;
}

.ListingShiftBookingsList .in-out-input-wrapper {
  display: flex;
  align-items: center;
}

.ListingShiftBookingsList .visible-items {
  overflow: visible !important;
  width: 90px;
}
.ListingShiftBookingsList .sub-header {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 9px 11px;
  border-bottom: 1px solid #e3e3e3;
}
.ListingShiftBookingsList .sub-header label {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0;
  font-weight: 400;
}
.ListingShiftBookingsList .sub-header label input {
  margin-top: 0;
}
.internal-worker-pay-rate {
  position: relative;
}

.internal-worker-pay-rate a[data-tip] {
  position: absolute;
  inset: 0;
}
