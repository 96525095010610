/* Some really dirty overrides. */
.App .with-participant a {
  color: #1f2022;
  display: inline-block;
}
.App .with-participant a:hover {
  color: #236dab;
  text-decoration: none;
}
.App .with-participant a .user-line {
  vertical-align: text-bottom;
  position: relative;
  top: 2px;
}
.App .with-participant a .user-line .avatar-wrapper {
  display: inline-block;
  position: relative;
  top: 2px;
  left: 0;
}
