.type_of_workers {
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 10px;
  background-color: lightgray;
  padding: 2px 5px;
  display: inherit;
}

.cell_block {
  display: block;
}

.cell_inline_flex {
  display: inline-flex;
}

.cell_icon {
  align-self: center;
}

.cell_tooltip {
  font-size: 15px;
}

.work_location-moved-workers {
  font-size: 10px;
}

.shift-change {
  color: red;
}
