.App .pagination-record {
  margin-left: 20px;
  margin-bottom: 16px;
}
.App .entity-detail-item .pagination {
  margin-bottom: 6px;
}
.App table.entity-list {
  width: 100%;
}
.App table.entity-list .__react_component_tooltip {
  /* Prevent the tooltip from being rendered below the navigation. */
  z-index: 2000;
}
/* Minor hack here. Need to rework later on. */
.App table.table.entity-list thead tr th.title {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}
.App table.entity-list tr th {
  background: white;
  font-weight: normal;
  border-bottom-width: 1px;
  line-height: 18px;
  position: relative;
  min-width: 34px;
}
.App table.entity-list tr td.align-left,
.App table.entity-list tr th.align-left {
  text-align: left;
}
.App table.entity-list tr td.align-center,
.App table.entity-list tr th.align-center {
  text-align: center;
}
.App table.entity-list tr td.align-right,
.App table.entity-list tr th.align-right {
  text-align: right;
}
.App table.entity-list tr th.has-header-icon {
  padding: 0;
}
.App table.entity-list tr th.has-header-icon > .header-icon {
  display: flex;
  height: 37px;
  flex-direction: column;
  justify-content: center;
}
.App table.entity-list tr th.has-header-icon > .header-icon > .img {
  margin: -1px auto 0 auto;
  fill: #757575;
}
.App table.entity-list tr th.has-selector {
  padding: 0;
}
.App table.entity-list tr td {
  height: 100%;
  background: white;
  padding: 0;
}
.App table.entity-list tr.clickable td {
  cursor: pointer;
}

.App .entity-list-wrapper {
  overflow-x: auto;
}

.App .entity-list-wrapper.no-overflow {
  overflow-x: initial;
}

.App .entity-list-wrapper.no-links table.entity-list tr.clickable td {
  cursor: default;
}

.App table.entity-list tr th,
.App table.entity-list tr th a,
.App table.entity-list tr td a,
.App table.entity-list tr th span.entity-row,
.App table.entity-list tr td span.entity-row {
  height: 100%;
  padding: 9px 11px;
}
.App table.entity-list tr td a.no-padding {
  padding: 0;
}
.App table td.sub-row {
  margin: 0;
  color: #1f2022;
}
.App table td.button-padding .padded {
  padding: 8px 11px;
}
.App table .padded {
  padding: 9px 11px;
}
.App table .sub-row:not(:last-child) {
  border-bottom: 1px solid #e3e3e3;
}

/* Styles that make a table cell look darker than normal. */
/* To activate pass e.g. this to the entityList() options: */
/* { rowClasses: data => classnames({ dim: true }) } */
.App table.entity-list tr.dim td {
  background: #f9f9fa;
}
.App table.entity-list tr.dim td a {
  color: #8c8d8e;
}

.App table.entity-list tr td {
  /* This somehow allows the <a> inside to take up the full height. */
  height: 0vh;
}
.App table.entity-list tr td a,
.App table.entity-list tr td span.entity-row {
  display: block;
  box-sizing: content-box;
  color: #1f2022;
}
.App table.entity-list tr td a:hover,
.App table.entity-list tr td span.entity-row:hover {
  text-decoration: none;
}
.App table.entity-list > tbody.selected > tr td.placeholder,
.App table.entity-list > tbody:hover > tr.clickable td.placeholder {
  background: white;
}
.App table.entity-list > tbody.selected > tr td,
.App table.entity-list > tbody:hover > tr.clickable td:not(.sub-row) {
  background: #f3f8fb;
  color: #236dab;
}
.App table.entity-list > tbody.selected > tr td.one-liner a,
.App table.entity-list > tbody:hover > tr.clickable td.one-liner a {
  border-color: #f3f8fb;
}
.App table.entity-list > tbody.selected > tr td a,
.App table.entity-list > tbody:hover > tr.clickable td:not(.sub-row) a,
.App table.entity-list > tbody:hover > tr.clickable td:not(.sub-row) span.entity-row {
  color: #236dab;
  fill: #236dab;
}
.App table.entity-list th,
.App table.entity-list tr td.nowrap {
  white-space: nowrap;
}
.App table.entity-list tr td.hide-content * {
  visibility: hidden;
}
.App table.entity-list tr td.limit {
  max-width: 322px;
}
.App table.entity-list tr td.limit a {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.App table.entity-list tr td.selector {
  width: 1%;
}
.App table.entity-list tr td.numeric,
.App table.entity-list tr th.numeric {
  width: 1%;
  text-align: right;
}
.App table.entity-list tr td.enum,
.App table.entity-list tr th.enum {
}
.App table.entity-list tr td.icon,
.App table.entity-list tr th.icon {
  width: 1%;
  text-align: center;
}
.App table.entity-list tr td.icon a {
  padding-left: 11px;
  padding-right: 11px;
  overflow: hidden;
  height: 19px;
  line-height: 19px;
}
.App table.entity-list tr td.icon a .icon {
  position: relative;
  top: 1px;
}
.App table.entity-list tr td.icon img,
.App table.entity-list tr th.icon img {
  width: 18px;
  height: auto;
}
.App table.entity-list tr td.image,
.App table.entity-list tr th.image {
  width: 1%;
  white-space: nowrap;
}
.App table.entity-list tr td.shortened {
  width: 75px;
  overflow: hidden;
}
.App table.entity-list tr td.shortened a {
  width: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.App table.entity-list tr td.no-wrap a {
  white-space: nowrap;
}
.App table.entity-list tr td.one-liner {
  position: relative;
}
.App table.entity-list tr td.one-liner a {
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.App table.entity-list tr td.one-liner a:before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 28px;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 0) 100%
  );
}
.App table.entity-list tr.selected td.one-liner a:before,
.App table.entity-list tr.clickable:hover td.one-liner a:before {
  background: linear-gradient(
    to left,
    rgba(243, 248, 251, 1) 0%,
    rgba(243, 248, 251, 1) 25%,
    rgba(243, 248, 251, 0) 100%
  );
}
.App table.entity-list tr td.main,
.App table.entity-list tr th.main {
  width: 99%;
  text-align: left;
}
.App table.entity-list tr td.shared-main,
.App table.entity-list tr th.shared-main {
  width: 50%;
  text-align: left;
}
.App table.entity-list tr th.minimal-header {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.App table.entity-list tr td.minimal,
.App table.entity-list tr th.minimal {
  width: 1%;
  white-space: nowrap;
}
.App table.entity-list tr td.button-wrapper {
  white-space: nowrap;
  width: 1%;
}
.App table.entity-list tr td.button-wrapper a {
  margin: 0;
  padding: 1px 1px 1px 1px;
}
.App table.entity-list tr td.centered a {
  text-align: center;
}
.App table.entity-list tr td.image a {
  padding: 2px 2px;
}
.App table.entity-list tr td .small-padded {
  padding: 2px;
}
/* Normally, the first <td> in a row in the entity list gets a border-left.
This is only invalid if the first <td> is a subrow and not the first item in the structure.
Removes the double line on the first subrow on the listings page. */
.App table.entity-list tbody tr td.sub-row:not(.col-1) {
  border-left: 0;
}

.App table.entity-list .feedback.loading-failed {
  text-align: center;
}

.App .entity-list-controls {
  padding: 0 0 20px 0;
}
.App .entity-list-controls.record.no-filters {
  padding: 0;
}
.App .entity-list-controls .right {
  float: right;
  text-align: right;
}

.App table.entity-list tr td .data-rating {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 9px 10px;
}
.App table.entity-list tr td a .data-rating,
.App table.entity-list tr td span.entity-row .data-rating {
  padding: 0;
}

.App table.entity-list tr td.tags {
  max-width: 150px;
}

/* Colors for subrows. These were originally in syft-acp-core/components/entity-lists/ShiftList.css. */
/* They've been updated to also appear for regular rows. */
/* The following colors are supported: cyan, Flex+, gray, yellow, orange, red, green, purple. */

/* First, a fix for subrows to ensure they don't take on cyan as the default color on the shifts page. */

/* Gray */
.App table.entity-list > tbody > tr.clickable.gray td:not(.sub-row),
.App table.entity-list > tbody > tr > .sub-row.gray {
  background: #e6ebef;
  border-right-color: #d5dbe0;
}
.App table.entity-list > thead + tbody > tr.clickable.gray td:not(.sub-row),
.App table.entity-list > tbody + tbody > tr.clickable.gray td:not(.sub-row) {
  border-bottom-color: #d5dbe0;
}
.App table.entity-list > tbody:hover > tr.clickable.gray td:not(.sub-row),
.App table.entity-list > tbody > tr:hover > .sub-row.gray {
  background: #c1cbd6;
  border-right-color: #afbbc9;
}
.App table.entity-list > tbody > tr > .sub-row.gray,
.App table.entity-list > thead + tbody > tr.clickable.gray td:not(.sub-row),
.App table.entity-list > tbody + tbody > tr.clickable.gray td:not(.sub-row) {
  border-bottom-color: #d5dbe0 !important;
}
.App table.entity-list > tbody:hover > tr.clickable.gray td:not(.sub-row) a,
.App table.entity-list > tbody:hover > tr.clickable.gray td:not(.sub-row) span.entity-row,
.App table.entity-list > tbody > tr:hover > .sub-row.gray a {
  color: #3f4851;
  fill: #3f4851;
}

.App.acp-theme-dark table.entity-list > tbody > tr.clickable.gray td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody > tr > .sub-row.gray {
  background: #5e656c;
  border-right-color: #8c959e;
}
.App.acp-theme-dark table.entity-list > thead + tbody > tr.clickable.gray td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody + tbody > tr.clickable.gray td:not(.sub-row) {
  border-bottom-color: #8c959e;
}
.App.acp-theme-dark table.entity-list > tbody:hover > tr.clickable.gray td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody > tr:hover > .sub-row.gray {
  background: #2f3f52;
  border-right-color: #43566c;
}

/* Flex+ */
.App table.entity-list > tbody > tr.clickable.syftforce td:not(.sub-row),
.App table.entity-list > tbody > tr > .sub-row.syftforce {
  background: #eaeaea;
  border-right-color: #dadada;
}
.App table.entity-list > tbody > tr.clickable.syftforce td:not(.sub-row) a,
.App table.entity-list > tbody > tr.clickable.syftforce td:not(.sub-row) span.entity-row,
.App table.entity-list > tbody > tr > .sub-row.syftforce a {
  color: #484848;
}
.App table.entity-list > tbody tr.syftforce td.one-liner a:before {
  background: linear-gradient(
    to left,
    rgba(234, 234, 234, 1) 0%,
    rgba(234, 234, 234, 1) 25%,
    rgba(234, 234, 234, 0) 100%
  );
}
.App table.entity-list > tbody:hover tr.syftforce td.one-liner a:before {
  background: linear-gradient(
    to left,
    rgba(204, 204, 204, 1) 0%,
    rgba(204, 204, 204, 1) 25%,
    rgba(204, 204, 204, 0) 100%
  );
}
.App table.entity-list > tbody:hover > tr.clickable.syftforce td:not(.sub-row),
.App table.entity-list > tbody > tr:hover > .sub-row.syftforce {
  background: #cccccc;
  border-right-color: #bcbcbc;
}
.App table.entity-list > tbody:hover > tr.clickable.syftforce td:not(.sub-row) a,
.App table.entity-list > tbody:hover > tr.clickable.syftforce td:not(.sub-row) span.entity-row,
.App table.entity-list > tbody > tr:hover > .sub-row.syftforce a {
  color: #3f4851;
  fill: #3f4851;
}

.App.acp-theme-dark table.entity-list > tbody > tr.clickable.syftforce td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody > tr > .sub-row.syftforce {
  background: #8c8c8c;
  border-right-color: #b7b7b7;
  border-bottom-color: #b7b7b7;
}
.App.acp-theme-dark table.entity-list > tbody > tr.clickable.syftforce td:not(.sub-row) a,
.App.acp-theme-dark table.entity-list > tbody > tr.clickable.syftforce td:not(.sub-row) span.entity-row,
.App.acp-theme-dark table.entity-list > tbody > tr > .sub-row.syftforce a {
  color: white;
}
.App.acp-theme-dark table.entity-list > tbody:hover > tr.clickable.syftforce td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody > tr:hover > .sub-row.syftforce {
  background: #445163;
  border-right-color: #576779;
  border-bottom-color: #576779;
}
.App.acp-theme-dark table.entity-list > tbody tr.syftforce td.one-liner a:before {
  background: linear-gradient(
    to left,
    rgba(140, 140, 140, 1) 0%,
    rgba(140, 140, 140, 1) 25%,
    rgba(140, 140, 140, 0) 100%
  );
}
.App.acp-theme-dark table.entity-list > tbody:hover tr.syftforce td.one-liner a:before {
  background: linear-gradient(
    to left,
    rgba(70, 81, 99, 1) 0%,
    rgba(70, 81, 99, 1) 25%,
    rgba(70, 81, 99, 0) 100%
  );
}

/* Yellow */
.App table.entity-list > tbody > tr.clickable.yellow td:not(.sub-row),
.App table.entity-list > tbody > tr > .sub-row.yellow {
  background: #ffffdb;
  border-right-color: #e5e7b6;
}
.App table.entity-list > thead + tbody > tr.clickable.yellow td:not(.sub-row),
.App table.entity-list > tbody + tbody > tr.clickable.yellow td:not(.sub-row) {
  border-bottom-color: #e5e7b6;
}
.App table.entity-list > tbody:hover > tr.clickable.yellow td:not(.sub-row),
.App table.entity-list > tbody > tr:hover > .sub-row.yellow {
  background: #fcffa6;
  border-right-color: #e0e394;
}
.App table.entity-list > tbody > tr > .sub-row.yellow,
.App table.entity-list > thead + tbody > tr.clickable.yellow td:not(.sub-row),
.App table.entity-list > tbody + tbody > tr.clickable.yellow td:not(.sub-row) {
  border-bottom-color: #e5e7b6 !important;
}
.App table.entity-list > tbody:hover > tr.clickable.yellow td:not(.sub-row) a,
.App table.entity-list > tbody:hover > tr.clickable.yellow td:not(.sub-row) span.entity-row,
.App table.entity-list > tbody > tr:hover > .sub-row.yellow a {
  color: #715612;
  fill: #715612;
}

.App.acp-theme-dark table.entity-list > tbody > tr.clickable.yellow td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody > tr > .sub-row.yellow {
  background: #ada830;
  border-right-color: #cdd490;
}
.App.acp-theme-dark table.entity-list > thead + tbody > tr.clickable.yellow td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody + tbody > tr.clickable.yellow td:not(.sub-row) {
  border-bottom-color: #cdd490;
}
.App.acp-theme-dark table.entity-list > tbody > tr > .sub-row.yellow,
.App.acp-theme-dark table.entity-list > thead + tbody > tr.clickable.yellow td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody + tbody > tr.clickable.yellow td:not(.sub-row) {
  border-bottom-color: #cdd490 !important;
}
.App.acp-theme-dark table.entity-list > tbody:hover > tr.clickable.yellow td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody > tr:hover > .sub-row.yellow {
  background: #565e37;
  border-right-color: #637467;
}

/* Orange */
.App table.entity-list > tbody > tr.clickable.orange td:not(.sub-row),
.App table.entity-list > tbody > tr > .sub-row.orange {
  background: #fff6e4;
  border-right-color: #eadfc6;
}
.App table.entity-list > thead + tbody > tr.clickable.orange td:not(.sub-row),
.App table.entity-list > tbody + tbody > tr.clickable.orange td:not(.sub-row) {
  border-bottom-color: #eadfc6;
}
.App table.entity-list > tbody:hover > tr.clickable.orange td:not(.sub-row),
.App table.entity-list > tbody > tr:hover > .sub-row.orange {
  background: #fce8bc;
  border-right-color: #e0d1a9;
}
.App table.entity-list > tbody > tr > .sub-row.orange,
.App table.entity-list > thead + tbody > tr.clickable.orange td:not(.sub-row),
.App table.entity-list > tbody + tbody > tr.clickable.orange td:not(.sub-row) {
  border-bottom-color: #eadfc6 !important;
}
.App table.entity-list > tbody:hover > tr.clickable.orange td:not(.sub-row) a,
.App table.entity-list > tbody:hover > tr.clickable.orange td:not(.sub-row) span.entity-row,
.App table.entity-list > tbody > tr:hover > .sub-row.orange a {
  color: #82300e;
  fill: #82300e;
}

.App.acp-theme-dark table.entity-list > tbody > tr.clickable.orange td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody > tr > .sub-row.orange {
  background: #b47047;
  border-right-color: #d8a77d;
}
.App.acp-theme-dark table.entity-list > thead + tbody > tr.clickable.orange td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody + tbody > tr.clickable.orange td:not(.sub-row) {
  border-bottom-color: #d8a77d;
}
.App.acp-theme-dark table.entity-list > tbody > tr > .sub-row.orange,
.App.acp-theme-dark table.entity-list > thead + tbody > tr.clickable.orange td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody + tbody > tr.clickable.orange td:not(.sub-row) {
  border-bottom-color: #d8a77d !important;
}
.App.acp-theme-dark table.entity-list > tbody:hover > tr.clickable.orange td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody > tr:hover > .sub-row.orange {
  background: #5a4440;
  border-right-color: #695f5c;
}

/* Red */
.App table.entity-list > tbody > tr.clickable.red td:not(.sub-row),
.App table.entity-list > tbody > tr > .sub-row.red {
  background: #ffeded;
  border-right-color: #eed6d6;
}
.App table.entity-list > thead + tbody > tr.clickable.red td:not(.sub-row),
.App table.entity-list > tbody + tbody > tr.clickable.red td:not(.sub-row) {
  border-bottom-color: #eed6d6;
}
.App table.entity-list > tbody:hover > tr.clickable.red td:not(.sub-row),
.App table.entity-list > tbody > tr:hover > .sub-row.red {
  background: #fcd1d1;
  border-right-color: #e0bebe;
}
.App table.entity-list > tbody > tr > .sub-row.red,
.App table.entity-list > thead + tbody > tr.clickable.red td:not(.sub-row),
.App table.entity-list > tbody + tbody > tr.clickable.red td:not(.sub-row) {
  border-bottom-color: #eed6d6 !important;
}
.App table.entity-list > tbody:hover > tr.clickable.red td:not(.sub-row) a,
.App table.entity-list > tbody:hover > tr.clickable.red td:not(.sub-row) span.entity-row,
.App table.entity-list > tbody > tr:hover > .sub-row.red a {
  color: #920a0a;
  fill: #920a0a;
}

.App.acp-theme-dark table.entity-list > tbody > tr.clickable.red td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody > tr > .sub-row.red {
  background: #b66868;
  border-right-color: #d8b5b4;
}
.App.acp-theme-dark table.entity-list > thead + tbody > tr.clickable.red td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody + tbody > tr.clickable.red td:not(.sub-row) {
  border-bottom-color: #d8b5b4;
}
.App.acp-theme-dark table.entity-list > tbody > tr > .sub-row.red,
.App.acp-theme-dark table.entity-list > thead + tbody > tr.clickable.red td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody + tbody > tr.clickable.red td:not(.sub-row) {
  border-bottom-color: #d8b5b4 !important;
}
.App.acp-theme-dark table.entity-list > tbody:hover > tr.clickable.red td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody > tr:hover > .sub-row.red {
  background: #5a4150;
  border-right-color: #666677;
}

/* Green */
.App table.entity-list > tbody > tr.clickable.green td:not(.sub-row),
.App table.entity-list > tbody > tr > .sub-row.green {
  background: #e5ffde;
  border-right-color: #caeec1;
}
.App table.entity-list > tbody:hover > tr.clickable.green td:not(.sub-row),
.App table.entity-list > tbody > tr:hover > .sub-row.green {
  background: #bfffae;
  border-right-color: #aae39b;
}
.App table.entity-list > tbody > tr > .sub-row.green,
.App table.entity-list > thead + tbody > tr.clickable.green td:not(.sub-row),
.App table.entity-list > tbody + tbody > tr.clickable.green td:not(.sub-row) {
  border-bottom-color: #caeec1 !important;
}
.App table.entity-list > tbody:hover > tr.clickable.green td:not(.sub-row) a,
.App table.entity-list > tbody:hover > tr.clickable.green td:not(.sub-row) span.entity-row,
.App table.entity-list > tbody > tr:hover > .sub-row.green a {
  color: #295f1d;
  fill: #295f1d;
}

.App.acp-theme-dark table.entity-list > tbody > tr.clickable.green td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody > tr > .sub-row.green {
  background: #4eb048;
  border-right-color: #9ad48c;
}
.App.acp-theme-dark table.entity-list > tbody > tr > .sub-row.green,
.App.acp-theme-dark table.entity-list > thead + tbody > tr.clickable.green td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody + tbody > tr.clickable.green td:not(.sub-row) {
  border-bottom-color: #9ad48c !important;
}
.App.acp-theme-dark table.entity-list > tbody:hover > tr.clickable.green td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody > tr:hover > .sub-row.green {
  background: #2a6141;
  border-right-color: #4b7364;
}

/* Cyan */
.App table.entity-list > tbody > tr.clickable.cyan td:not(.sub-row),
.App table.entity-list > tbody > tr.cyan td:not(.sub-row) {
  border-right-color: #c4e2ec;
  border-left-color: #c4e2ec;
  background: #e3f9ff;
}
.App table.entity-list > thead + tbody > tr.clickable.cyan td:not(.sub-row),
.App table.entity-list > tbody + tbody > tr.clickable.cyan td:not(.sub-row) {
  border-bottom-color: #c4e2ec;
}
.App table.entity-list > tbody:hover > tr.clickable.cyan td:not(.sub-row),
.App table.entity-list > tbody > tr.cyan:hover td:not(.sub-row) {
  border-right-color: #bddce6;
  background: #d6eef7;
}
.App table.entity-list > tbody:hover > tr.clickable.cyan td:not(.sub-row) a,
.App table.entity-list > tbody:hover > tr.clickable.cyan td:not(.sub-row) span.entity-row,
.App table.entity-list > tbody > tr.cyan:hover td:not(.sub-row) a,
.App table.entity-list > tbody > tr.cyan:hover td:not(.sub-row) span.entity-row {
  color: #394f58;
  fill: #394f58;
}

.App.acp-theme-dark table.entity-list > tbody > tr.clickable.cyan td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody > tr.cyan td:not(.sub-row) {
  border-right-color: #98c6fa;
  border-left-color: #98c6fa;
  background: #5b72e2;
}
.App.acp-theme-dark table.entity-list > thead + tbody > tr.clickable.cyan td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody + tbody > tr.clickable.cyan td:not(.sub-row) {
  border-bottom-color: #98c6fa;
}
.App.acp-theme-dark table.entity-list > tbody:hover > tr.clickable.cyan td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody > tr.cyan:hover td:not(.sub-row) {
  border-right-color: #436d9c;
  background: #21468f;
}

/* Purple */
.App table.entity-list > tbody > tr.clickable.purple td:not(.sub-row),
.App table.entity-list > tbody > tr.purple td:not(.sub-row) {
  border-right-color: #d5d0f1;
  border-left-color: #d5d0f1;
  background: #eee6fe;
}

.App table.entity-list > thead + tbody > tr.clickable.purple td:not(.sub-row),
.App table.entity-list > tbody + tbody > tr.clickable.purple td:not(.sub-row) {
  border-bottom-color: #d5d0f1;
}

.App table.entity-list > tbody:hover > tr.clickable.purple td:not(.sub-row),
.App table.entity-list > tbody > tr.purple:hover td:not(.sub-row) {
  border-right-color: #c7c4e3;
  background: #e6deff;
}
.App table.entity-list > tbody:hover > tr.clickable.purple td:not(.sub-row) a,
.App table.entity-list > tbody:hover > tr.clickable.purple td:not(.sub-row) span.entity-row,
.App table.entity-list > tbody > tr.purple:hover td:not(.sub-row) a,
.App table.entity-list > tbody > tr.purple:hover td:not(.sub-row) span.entity-row {
  color: #4c445c;
  fill: #4c445c;
}

.App.acp-theme-dark table.entity-list > tbody > tr.clickable.purple td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody > tr.purple td:not(.sub-row) {
  border-right-color: #ba9ef3;
  border-left-color: #ba9ef3;
  background: #8c6ad0;
}

.App.acp-theme-dark table.entity-list > thead + tbody > tr.clickable.purple td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody + tbody > tr.clickable.purple td:not(.sub-row) {
  border-bottom-color: #ba9ef3;
}
.App.acp-theme-dark table.entity-list > tbody:hover > tr.clickable.purple td:not(.sub-row),
.App.acp-theme-dark table.entity-list > tbody > tr.purple:hover td:not(.sub-row) {
  border-right-color: #555b99;
  background: #3e4385;
}

/* End of colored rows. */

.App.acp-theme-dark table.entity-list tr th.has-header-icon > .header-icon > .img {
  fill: #8899a6;
}

.App.acp-theme-dark table.entity-list tr td.one-liner a:before {
  background: linear-gradient(
    to left,
    rgba(22, 31, 42, 1) 0%,
    rgba(22, 31, 42, 1) 25%,
    rgba(22, 31, 42, 0) 100%
  );
}
.App.acp-theme-dark table.entity-list tr.selected td.one-liner a:before,
.App.acp-theme-dark table.entity-list tr.clickable:hover td.one-liner a:before {
  background: linear-gradient(
    to left,
    rgba(35, 47, 62, 1) 0%,
    rgba(35, 47, 62, 1) 25%,
    rgba(35, 47, 62, 0) 100%
  );
}
.App.acp-theme-dark table.entity-list tr th {
  background: #15202b;
  border-color: #38444d;
}
.App.acp-theme-dark table.entity-list tr td {
  background-color: #15202b;
  border-color: #38444d;
}
.App.acp-theme-dark table.entity-list tr td a {
  color: white;
  fill: white;
}
.App.acp-theme-dark table.entity-list tr td a:hover {
}
.App.acp-theme-dark table.entity-list > tbody.selected > tr td.placeholder,
.App.acp-theme-dark table.entity-list > tbody:hover > tr.clickable td.placeholder {
  background: #15202b;
}
.App.acp-theme-dark table.entity-list > tbody.selected > tr td,
.App.acp-theme-dark table.entity-list > tbody:hover > tr.clickable td:not(.sub-row) {
  background: #222f3e;
  color: #c5d9ea;
}
.App.acp-theme-dark table.entity-list > tbody.selected > tr td.one-liner a,
.App.acp-theme-dark table.entity-list > tbody:hover > tr.clickable td.one-liner a {
  border-color: #222f3e;
}
.App.acp-theme-dark table.entity-list > tbody.selected > tr td a,
.App.acp-theme-dark table.entity-list > tbody:hover > tr.clickable td:not(.sub-row) a,
.App.acp-theme-dark table.entity-list > tbody:hover > tr.clickable td:not(.sub-row) span.entity-row {
  color: #c5d9ea;
  fill: #c5d9ea;
}
