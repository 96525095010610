.page-text-changelog ul li {
  padding-left: 78px;
  position: relative;
}
.page-text-changelog ul li > p {
  padding: 0;
  margin: 0;
}
.page-text-changelog ul li > p > a:first-child,
.page-text-changelog ul li > a:first-child {
  position: absolute;
  left: 0;
}
/* Move nested lists to the left. */
.page-text-changelog ul li > ul {
  margin-left: -78px;
}
