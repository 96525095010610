$margin: 3px;

.filter-roles-skills {
  $self: &;

  display: flex;

  &__col {
    &:nth-child(2) {
      flex: 1;
    }
  }

  &__divider {
    margin-right: $margin;
  }

  &__item {
    display: inline-block;
    width: 187px;
    margin-right: $margin;
    margin-bottom: $margin;
  }

  &__button {
    display: inline-flex !important;
    align-items: center;
    margin-right: $margin;

    &:last-child {
      margin-right: unset;
    }
  }
}
