.plain-text {
  word-break: break-word;
}
.plain-text .secondary {
  font-size: 11px;
  margin-left: 8px;
}
.plain-text .icon {
  display: inline-block;
  fill: #757575;
  height: 13px;
  line-height: 13px;
  vertical-align: text-bottom;
  margin-right: 4px;
}

.boxed-input:invalid {
  border: 1px solid #ea3a3a;
}

.boxed-input.default-value {
  background-color: #faffbe;
}
.App.acp-theme-dark .plain-text .icon {
  fill: #8899a6;
}
/* Note: this specifically targets the plain-text fields used by DataText only,
 * and overrides the large line-height that these components normally get.
 * DataText should be able to have multiple lines of text. */
.App .editable-table tr th.field .component .DataText.plain-text,
.App .editable-table tr td.field .component .DataText.plain-text {
  line-height: 20px;
  padding-top: 8px;
}
