.compliance-detail {
  $color: #ff7a00;

  .role-name {
    font-weight: 500;
    color: #1f2022;
  }
  .policy-name {
    border: 1px solid $color;
    color: $color;
    border-radius: 4px;
    padding: 4px;
    margin-right: 12px;
    line-height: 12px;
    display: inline-block;
  }
}
