.acp-notifier-block {
  position: absolute;
  z-index: 10;
  top: 44px;
  width: 100%;
}
.acp-notifier-block.has-col-groups {
  top: 82px;
}
.acp-notifier-block .inner {
  margin: auto;
  width: 23vw;
  background: #fff;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  color: #757575;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.acp-notifier-block .inner .message {
  margin-top: -5px;
}
