.App .acp-input-container .acp-input-checkbox-label {
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  margin: 0;
  min-width: 36px;
}
.App .acp-input-container .acp-input-checkbox {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: bottom;
  position: relative;
  top: 2px;
}

.App .acp-input-container .acp-input-checkbox:focus {
  box-shadow: none;
  border-color: none;
}
