.acp-fulfilment-bookings-list {
  padding: 10px;
  max-height: 400px;
  max-width: min-content;
  background: '#f3f4f5';
}

.platform-name {
  padding: 2px;
  margin: 7px;
  left: 50%;
  text-align: center;
  width: 35px;
  text-indent: 0.5px;
  background-color: #e3e3e3;
}
