.form-control.acp-datepicker-group {
  display: inline-flex;
  border: 1px solid #ccc;
  align-items: center;
  flex-direction: row;
  vertical-align: baseline;
  padding: 0 0 0 12px;
  border-radius: 2px;
}

.form-control.acp-datepicker-group > * {
  flex: 1;
}

.form-control.acp-datepicker-group input {
  padding: 0;
  font-size: 14px;
}
.form-control.acp-datepicker-group.acp-datepicker-group--disabled {
  background: #f3f4f5;
  color: #6d6d6d;
  cursor: not-allowed;
}
/* hide underline */
.form-control.acp-datepicker-group .MuiInput-underline:before,
.form-control.acp-datepicker-group .MuiInput-underline:after {
  content: none;
}
.form-control.acp-datepicker-group .MuiFormHelperText-root.Mui-error {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 12px;
  z-index: 100;
}
