.App .editable-table {
  padding-top: 7px;
  padding-bottom: 7px;
  table-layout: fixed;
}
.App .editable-table td,
.App .editable-table th {
  line-height: 35px;
}
.App .editable-table th {
  font-weight: 500;
  color: #1f2022;
}
.App.acp-theme-dark .editable-table th {
  color: #cad1de;
}
.App .editable-table td .entity-checkbox label {
  line-height: 19px;
}
.App .editable-table td.field {
  /* By default, reset padding for all columns containing fields */
  padding: 0;
}
.App .editable-table td .boxed-input {
  margin-bottom: 3px;
}
.App .editable-table tr.separator {
}
.App .editable-table tr.separator > td {
  padding: 0 0 12px 0;
  padding-right: 0 !important;
}
.App .editable-table tr.separator > td .line {
  border-bottom: 1px solid #e3e3e3;
  padding: 10px 0 0 0;
}
.App.acp-theme-dark .editable-table tr.separator > td .line {
  border-color: #38444d;
}
.App .editable-table tr td.field .component-wrapper .expl {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 7px;
  margin-left: 2px;
}
.App .editable-table tr th.pre-icon,
.App .editable-table tr td.pre-icon,
.App .editable-table tr th.field .component .plain-text,
.App .editable-table tr td.field .component .plain-text {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 35px;
}
.App .editable-table tr th.field .component .plain-text,
.App .editable-table tr td.field .component .plain-text {
  padding-left: 13px;
  padding-top: 1px;
}
.App .editable-table tr th.field .component .plain-text.data-rating,
.App .editable-table tr td.field .component .plain-text.data-rating {
  line-height: 25px;
}
.App .editable-table .field .component .Select,
.App .editable-table .field .component .DataBooleanMatrix {
  margin-bottom: 5px;
}
.App .editable-table tr th.pre-icon,
.App .editable-table tr td.pre-icon {
  border-right: 0;
}
.App .editable-table tr td.icon,
.App .editable-table tr th.icon {
  width: 12px;
  box-sizing: content-box;
  text-align: center;
}
.App .editable-table tr td.right-filler,
.App .editable-table tr td.right-uploader {
  white-space: nowrap;
  /* This ensures there's no jump when the uploader goes to stage 2. */
  width: 227px;
}
.App .editable-table tr td.icon a {
  width: 12px;
  padding-left: 11px;
  padding-right: 11px;
  box-sizing: content-box;
  overflow: hidden;
}
.App .editable-table tr td.icon img,
.App .editable-table tr th.icon img {
  width: 12px;
  height: auto;
}

/* TODO Remove */
.App .editable-table tr td.icon img,
.App .editable-table tr th.icon img {
  width: 12px;
  height: auto;
}
