.column {
  overflow: visible !important;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.ReactVirtualized__Table__row {
  overflow: visible !important;
}

.ReactVirtualized__Table__Grid {
  overflow: visible !important;
}

.reactVirtualizedGrid:focus {
  outline: none;
}
.data-industries-roles-table{
  padding-left: 9px;
}

.data-industries-roles-table .multi-level-selector-container {
  max-width: none;
}

.data-industries-roles-table .acp-table-cell-container.is-form.is-not-clickable{
  display: flex;
  align-items: center;
}
