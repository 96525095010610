.checkCircleIcon {
  margin: 5px;
  border-radius: 16px;
  background: green;
  height: 16px;
  width: 16px;
}

.checkCircleIcon svg {
  margin: 0 0 0 20%;
}
