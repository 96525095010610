.AutocompleteWorkers {
  position: relative;
  height: 35px;
}
/* FIXME: the CSS for UserAvatar is nasty. */
.AutocompleteWorkers .UserAvatar {
  position: absolute !important;
  top: 7px !important;
  left: 7px !important;
  z-index: 10000;
}
.AutocompleteWorkers .UserAvatar,
.AutocompleteWorkers .UserAvatar .avatar-wrapper {
  width: 23px !important;
  height: 23px !important;
}
.AutocompleteWorkers .UserAvatar img {
  width: 23px;
}
.AutocompleteWorkers .FilterInput input[type='text'] {
  padding-left: 32px;
}
/** Fix bug for avatar display. */
.AutocompleteWorkers .FilterInput .form-control {
  padding-left: 32px;
  /* Without the top and bottom margins included. Normally 35px. */
  line-height: 22px;
}
.App .AutocompleteWorkers .dropdown-menu {
  z-index: 500000000; /* It really needs to be this big. */
}
.App .AutocompleteWorkers .dropdown-menu li.disabled > a,
.App .AutocompleteWorkers .dropdown-menu li.disabled > a:hover,
.App .AutocompleteWorkers .dropdown-menu li.disabled > a:focus {
  /* Prevent the row highlight from messing with the color. */
  color: #1f2022 !important;
}
