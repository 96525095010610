.DataExternalUUIDsItem {
  margin-right: 8px;
  padding: 0 16px 0 0;
  display: inline-block;
  height: 22px;
  line-height: 22px;
  position: relative;
  border-radius: 3px 0 0 3px;
  background: #4f99fa;
  font-size: 12px;
  cursor: pointer;
}
.DataExternalUUIDsItem.is-saved {
  background: #777;
}
.DataExternalUUIDsItem.is-being-edited.is-new {
  background-color: #a2c5fd;
}
.DataExternalUUIDsItem.is-being-edited.is-new .delete {
  background-color: #a2c5fd;
}
.DataExternalUUIDsItem.is-being-edited.is-saved {
  background-color: #a6a6a6;
}
.DataExternalUUIDsItem.is-being-edited.is-saved .delete {
  background-color: #a6a6a6;
}

.DataExternalUUIDsItem.is-saved .delete:before {
  background-color: #777;
  border-left-color: #ccc;
}
.DataExternalUUIDsItem .content {
  font-weight: normal;
  padding-left: 6px;
  display: inline-block;
  height: 22px;
  padding-right: 6px;
  line-height: 22px;
  border-radius: 3px 0 0 3px;
}
.DataExternalUUIDsItem .content:hover {
  background-color: #013b98;
}
.DataExternalUUIDsItem.is-saved .content:hover {
  background-color: #333;
}
.DataExternalUUIDsItem.to-delete {
  opacity: 0.4;
}
.DataExternalUUIDsItem.hard-skill .content {
  font-weight: bold;
}
.DataExternalUUIDsItem .delete:before {
  content: '✕';
  background: #4f99fa;
  border-left: 1px solid #b3d0ff;
  border-radius: 0 3px 3px 0;
  position: absolute;
  display: block;
  line-height: 22px;
  width: 20px;
  top: 0;
  right: -4px;
  cursor: pointer;
  bottom: 0;
}
.DataExternalUUIDsItem .delete:hover:before {
  background: #013b98;
}
.DataExternalUUIDsItem.is-saved .delete:hover:before {
  background: #333;
}
