.App .NameAvatarImage,
#modals .NameAvatarImage {
  width: 25px;
  height: 25px;
  position: relative;
  display: inline-block;
  margin: 0;
}
.App .NameAvatarImage.with-name,
#modals .NameAvatarImage.with-name {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -13px;
}
.App .NameAvatarImage .avatar-image-wrapper,
#modals .NameAvatarImage .avatar-image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}
.App .NameAvatarImage .avatar-image-wrapper .avatar-img,
#modals .NameAvatarImage .avatar-image-wrapper .avatar-img,
.App .NameAvatarImage .avatar-image-wrapper .avatar-border,
#modals .NameAvatarImage .avatar-image-wrapper .avatar-border {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.App .NameAvatarImage .avatar-image-wrapper .avatar-img,
#modals .NameAvatarImage .avatar-image-wrapper .avatar-img {
  z-index: 5;
}
.App .NameAvatarImage .avatar-image-wrapper .avatar-border,
#modals .NameAvatarImage .avatar-image-wrapper .avatar-border {
  z-index: 6;
  border-radius: 3px;
  box-shadow: inset 0px 0px 0px 0.9px rgba(0, 0, 0, 0.2);
}
.App.acp-theme-dark .NameAvatarImage .avatar-image-wrapper .avatar-border,
#modals.acp-theme-dark .NameAvatarImage .avatar-image-wrapper .avatar-border {
  box-shadow: inset 0px 0px 0px 0.9px #38444d;
}
/* Invert the 'guest' and 'empty' images if we're on dark mode. */
.App.acp-theme-dark .NameAvatarImage.with-fallback-image .avatar-image-wrapper img,
#modals.acp-theme-dark .NameAvatarImage.with-fallback-image .avatar-image-wrapper img {
  filter: invert(1);
}
/*
.App .NameAvatarImage .avatar-image-wrapper,
#modals .NameAvatarImage .avatar-image-wrapper {
  position: relative;
  overflow: hidden;
}
.App .NameAvatarImage .avatar-image-wrapper img,
.App .NameAvatarImage .avatar-image-wrapper .border,
#modals .NameAvatarImage .avatar-image-wrapper img,
#modals .NameAvatarImage .avatar-image-wrapper .border {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 5;
}
.App .avatar-image-wrapper .border,
#modals .avatar-image-wrapper .border {
  z-index: 6;
}
.App .NameAvatarImage.shape-rounded-square .avatar-image-wrapper,
.App .NameAvatarImage.shape-rounded-square .avatar-image-wrapper .border,
.App .NameAvatarImage.shape-rounded-square .avatar-image-wrapper img,
#modals .NameAvatarImage.shape-rounded-square .avatar-image-wrapper,
#modals .NameAvatarImage.shape-rounded-square .avatar-image-wrapper .border,
#modals .NameAvatarImage.shape-rounded-square .avatar-image-wrapper img {
  border-radius: 3px;
}
.App .NameAvatarImage.size-xsmall,
.App .NameAvatarImage.size-xsmall .avatar-image-wrapper,
#modals .NameAvatarImage.size-xsmall,
#modals .NameAvatarImage.size-xsmall .avatar-image-wrapper {
  width: 23px;
  height: 23px;
}
.App .NameAvatarImage.size-xsmall .avatar-image-wrapper,
#modals .NameAvatarImage.size-xsmall .avatar-image-wrapper {
  margin-right: 3px;
}

.App.acp-theme-dark .NameAvatarImage .avatar-image-wrapper img[src^="/static/media/guest"],
#modals.acp-theme-dark .NameAvatarImage .avatar-image-wrapper img[src^="/static/media/guest"] {
  filter: invert(1);
}
*/
