.data_tab_flexer {
  margin-bottom: -15px;
}

.data_tab_link {
  margin-left: 220px;
}

.data_tab_access_instructions {
  float: left;
}

.data_tab_access_instructions_margin {
  width: 220px;
}

.data_tab_access_instructions_gap {
  margin-left: 145px;
}

.data_tap_info {
  margin-top: 10px;
}
