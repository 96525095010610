$colors: (
  'red': (
    background: #ee5d59,
    color: white,
  ),
  'orange': (
    background: #fd974d,
    color: white,
  ),
  'yellow': (
    background: #edc537,
    color: white,
  ),
  'green': (
    background: #52d356,
    color: white,
  ),
  'aqua': (
    background: #29ca9a,
    color: white,
  ),
  'cyan': (
    background: #00c1de,
    color: white,
  ),
  'blue': (
    background: #4999f6,
    color: white,
  ),
  'purple': (
    background: #a37bfa,
    color: white,
  ),
  'magenta': (
    background: #d85bde,
    color: white,
  ),
  'pink': (
    background: #f9569c,
    color: white,
  ),
  'gray': (
    background: #a7a4a4,
    color: white,
  ),
  'red-light': (
    background: #ffecee,
    color: #6d110d,
  ),
  'orange-light': (
    background: #ffede6,
    color: #693614,
  ),
  'yellow-light': (
    background: #fff1ce,
    color: #725318,
  ),
  'green-light': (
    background: #dfffdc,
    color: #005f0b,
  ),
  'aqua-light': (
    background: #e0fbee,
    color: #00563a,
  ),
  'cyan-light': (
    background: #e1f7ff,
    color: #004d69,
  ),
  'blue-light': (
    background: #e7f6ff,
    color: #00327c,
  ),
  'purple-light': (
    background: #f4f2ff,
    color: #431a6e,
  ),
  'magenta-light': (
    background: #feeaff,
    color: #561054,
  ),
  'pink-light': (
    background: #ffecf4,
    color: #660c3d,
  ),
  'gray-light': (
    background: #f3f0f0,
    color: #555252,
  ),
);

/** Returns a named value from the $colors map. */
@function get-value($color, $tone) {
  @return map-get(map-get($colors, $color), $tone);
}

.acp-cell-tag {
  background-color: gray;
  display: inline-block;
  margin: 0 4px 4px 0;
  padding: 2px 5px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  border-radius: 2.5px;
  border: 1px solid rgba(5, 2, 2, 0.08);
  border-bottom: 1px solid rgba(5, 2, 2, 0.22);

  &:hover {
    opacity: 0.8;
    border-color: rgba(5, 2, 2, 0.2);
    border-bottom-color: rgba(5, 2, 2, 0.3);
  }

  @each $color, $values in $colors {
    &.tag-color-#{"" + $color + ""} {
      background-color: get-value($color, 'background');
      color: get-value($color, 'color');
    }
  }

  &:last-child {
    margin-right: 0;
  }
}
