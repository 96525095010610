.App .DataBooleanMatrix tr td {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
.App .DataBooleanMatrix tr td,
.App .DataBooleanMatrix tr th {
  width: 50px;
  text-align: center;
}
.App .DataBooleanMatrix {
  margin-bottom: 0;
}
.App .DataBooleanMatrix .entity-checkbox {
  width: 100%;
}
