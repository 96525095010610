.App .acp-header-icon {
  display: flex;
  height: 37px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.App .acp-header-icon > .img {
  fill: #757575;
  margin: 0 auto 0 auto;
}
