.acp-wrapper {
  text-align: left;
  margin: 0 0 20px 0;
}
.acp-wrapper.hasNoMargin {
  margin-bottom: 0;
}
.acp-wrapper.noRounding {
  border-radius: 0;
}
.acp-wrapper.isIntegrated {
  border: 1px solid #e3e3e3;
  background: white;
  border-radius: 3px;
}
.acp-wrapper.noBottomBorder {
  border-bottom: 0;
}

.App.acp-theme-dark .acp-wrapper {
  background-color: #15202b;
  border-color: #38444d;
}

/* Glue together multiple detail items with 'connected'. */
.acp-wrapper.isConnected:first-of-type {
  margin-bottom: 0;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.acp-wrapper.isConnected + .acp-wrapper.isConnected {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0;
  border-bottom: 0;
}
.acp-wrapper.isConnected + .acp-wrapper.isConnected:last-child {
  border-bottom: 1px solid #e3e3e3;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
