.historic-holiday-pay-modal {
  &__row {
    margin-bottom: 1em;
  }

  &__html {
    p {
      margin-top: 0 !important;
    }
  }

  input {
    width: initial !important;
  }
}
