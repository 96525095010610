.time-punch-view-select {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 12px 2px;
  border-bottom: 1px solid #e3e3e3;
}
.time-punch-view-select label {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0;
  font-weight: 400;
}
.time-punch-view-select label input {
  margin-top: 0;
}
