.App .sub-nav {
  background: white;
  box-shadow: none;
  border-bottom: 1px solid #d9dce0;
}
.App .sub-nav.navbar .navbar-nav > li > a {
  padding-bottom: 13px;
  position: relative;
  color: #1f2022;
  top: 1px;
  text-shadow: none;
  border-bottom: 2px solid transparent;
  transition: border-color 0.05s cubic-bezier(0.27, 0.62, 0.43, 1);
  padding-left: 11px;
  padding-right: 11px;
}
.App .sub-nav.navbar .navbar-nav > li > a:hover,
.App .sub-nav.navbar .navbar-nav > li > a:focus {
  border-color: #934ad2;
}
.App .sub-nav button.navbar-toggle {
  background-color: #aaa;
}
.App .sub-nav button.navbar-toggle .icon-bar {
  background-color: #333;
}
.App .sub-nav.navbar .navbar-nav > .active > a {
  text-shadow: none;
  background: white;
  box-shadow: none;
  border-color: #934ad2;
  color: #934ad2;
}
.App.acp-theme-dark .sub-nav.navbar .navbar-nav > .active > a {
  color: #b487f8;
}
.App .sub-nav.navbar .navbar-nav > .active > a:hover,
.App .sub-nav.navbar .navbar-nav > .active > a:focus {
}
@media (max-width: 767px) {
  .App .sub-nav.navbar .navbar-nav > li > a {
    border: none;
  }
  .App .sub-nav.navbar .navbar-nav > li > a:hover,
  .App .sub-nav.navbar .navbar-nav > li > a:focus {
    color: #934ad2;
  }
  .App.acp-theme-dark .sub-nav.navbar .navbar-nav > li > a:hover,
  .App.acp-theme-dark .sub-nav.navbar .navbar-nav > li > a:focus {
    color: #b487f8;
  }
}

.App.acp-theme-dark .sub-nav {
  background: #1c2938;
  box-shadow: none;
  border-color: #38444d;
  fill: #e2e3e5;
  color: #e2e3e5;
}

.App.acp-theme-dark .sub-nav.navbar .navbar-nav > li > a {
  color: #e2e3e5;
}
.App.acp-theme-dark .sub-nav.navbar .navbar-nav > .active > a {
  background: #1c2938;
  color: #b487f8;
}
