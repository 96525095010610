.App .acp-item-header {
  padding: 11px 20px 10px;
}
.App .acp-item-header.border {
  border-bottom: 1px solid #e3e3e3;
}
.App .acp-item-header .header-text {
  margin: 0;
  display: inline-block;
  margin-right: 8px;
}
.App .acp-item-header .type-main {
  font-size: 15px;
  line-height: 25px;
  color: black;
}
.App .acp-item-header .type-sub {
  font-size: 15px;
  line-height: 25px;
  color: #444;
}

.App.acp-theme-dark .acp-item-header .header-text.type-main {
  color: white;
}
.App.acp-theme-dark .acp-item-header .header-text.type-sub {
  color: #8899a6;
}
.App.acp-theme-dark .acp-item-header.border {
  border-color: #38444d;
}
