.acp-input-dropdown {
  padding: 0 0px;
  min-width: 60px;
}
.acp-input-dropdown > select {
  width: 100%;
  height: 33px;
  color: #555555;
  background: white;
  border: 1px solid #ccc;
}
.acp-input-dropdown > select:disabled {
  background: #f3f4f5;
  cursor: not-allowed;
}
.acp-input-dropdown > select > option.placeholder {
}
