.import-submit-button {
  margin-left: 20px;
  width: 150px;
}

.import-row {
  height: 50px;
}

.import-log-events-list {
  margin: 5px;
}
