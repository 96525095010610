.abbr-header {
  position: relative;
  line-height: 19px;

  &__display {
    position: absolute;
    left: -3px;
    text-align: center;
    width: 19px;
    text-indent: 0.5px;
    border-radius: 50%;
    background-color: #e3e3e3;
  }
}

.align-center .abbr-header__display {
  left: 50%;
  line-height: 19px;
  margin-left: -10px;
}

.align-right .abbr-header__display {
  left: auto;
  right: -4px;
}

.App.acp-theme-dark .abbr-header__display {
  background-color: #39444d;
}
