.App .acp-star,
#modals .acp-star {
  display: inline-block;
  height: 14px;
  line-height: 14px;
}
.App .acp-star svg,
#modals .acp-star svg {
  width: 14px;
  height: 14px;
}

/* Full star: display #full star red. */
.App .acp-star.is-full svg #full,
#modals .acp-star.is-full svg #full {
  fill: #fdcc25;
}
.App .acp-star.is-full svg #half,
#modals .acp-star.is-full svg #half {
  display: none;
}

/* Half star: display both #full and #half stars, one gray and one red. */
.App .acp-star.is-half svg #full,
#modals .acp-star.is-half svg #full {
  fill: #e9ebee;
}
.App .acp-star.is-half svg #half,
#modals .acp-star.is-half svg #half {
  fill: #fdcc25;
}

/* Empty star: display #full star as gray. */
.App .acp-star.is-empty svg #full,
#modals .acp-star.is-empty svg #full {
  fill: #e9ebee;
}
.App .acp-star.is-empty svg #half,
#modals .acp-star.is-empty svg #half {
  display: none;
}
