.WorkerQuickview .info-table {
  margin-bottom: 10px;
}
/* Small position fix for the average rating. */
.WorkerQuickview .info-table .data-rating {
  top: 3px;
}
.WorkerQuickview .info-table th {
  white-space: nowrap;
}
