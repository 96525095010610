.EmployerAlertBadge {
  font-size: 11px;
  padding: 0 8px;
  display: inline-block;
  line-height: 16px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #777;
  border-radius: 10px;
  position: relative;
  top: -1px;
}
