.App .item-header {
  padding: 11px 20px 10px;
}
.App .item-header.border {
  border-bottom: 1px solid #e3e3e3;
}
.App .item-header.padding-0 {
  padding: 0
}
.App .item-header h2 {
  font-size: 15px;
  line-height: 25px;
  color: black;
}
.App .item-header h3 {
  font-size: 15px;
  line-height: 25px;
  color: #444;
}
.App .item-header h2,
.App .item-header h3 {
  margin: 0;
  display: inline-block;
  margin-right: 8px;
}

.App.acp-theme-dark .item-header h2 {
  color: white;
}
.App.acp-theme-dark .item-header h3 {
  color: #8899a6;
}
.App.acp-theme-dark .item-header.border {
  border-color: #38444d;
}
