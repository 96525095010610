.acp-filter-group {
  $self: &;

  display: inline-block;
  vertical-align: top;

  &--max-width {
    width: 100%;

    #{$self}__label,
    #{$self}__button-group {
      width: 100%;
    }
  }

  &__label {
    font-weight: normal;
    margin: 0 0 3px 2px;
    &.compact {
      margin: 0px;
    }

    &__text {
      font-weight: normal;
      margin: 0 0 4px 2px;
      &.compact {
        margin: 0px;
      }
    }
  }

  &__button-group {
    display: block;
  }
}
