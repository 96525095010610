.acp-list-popover {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

.acp-list-popover-container {
  margin: 10px;
  width: 800px;
}

.acp-list-filter-container {
  text-align: left;
}

.acp-list-filter-popup {
  display: inline-flex;
}

.acp-list-filter-button {
  width: 120px;

  &__label {
    float: left;
  }
}
