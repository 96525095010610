.data-rating {
  letter-spacing: 2px;
  color: #2a2b2e;
  font-size: 15px;
  line-height: 13px;
  position: relative;
}
.App.acp-theme-dark .data-rating {
  color: #8899a6;
}
.editable-table .field .component .data-rating {
  left: -2px;
}
.entity-detail-item .data-rating {
  padding-top: 2px;
}
.data-rating .value {
  margin-left: 6px;
  letter-spacing: 0;
  font-size: 13px;
}
.data-rating .rows,
.data-rating .rows .value,
.data-rating .rows .bg,
.data-rating .rows .fg {
  display: inline-block;
}
.data-rating .rows {
  position: relative;
  white-space: nowrap;
}
.data-rating .rows .fg {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  color: #fdcc25;
}
.data-rating .rows .bg {
  position: relative;
  color: #e9ebee;
}
.App.acp-theme-dark .data-rating .rows .bg {
  color: #161f2a;
}
.data-rating .rows i.half {
  color: transparent;
  position: relative;
}
.data-rating .rows i.half::before {
  content: '★';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  color: #fdcc25;
  width: 45%;
  overflow: hidden;
}
.data-rating .rows i {
  font-style: normal;
  display: inline-block;
}

.data-rating .remove-stars-icon {
  padding-right: 5px;
}

.data-rating .remove-stars-tooltip {
  letter-spacing: 0;
}
/* Hide until hover if the item has not been rated yet. */
.data-rating.not-rated-yet {
  opacity: 0;
}

.data-rating.not-rated-yet:hover {
  opacity: 1;
}
