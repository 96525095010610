.AlertItem {
  border-bottom: 1px solid #ddd;
  padding: 9px 11px;
  display: block;
  color: #1f2022;
}
.AlertItem.is-link:hover {
  background: #f3f8fb;
  color: #236dab;
  text-decoration: none;
}
.AlertItem:last-child {
  border-bottom: none;
}
.AlertItem .message {
}
.AlertItem .message .time {
  float: right;
  padding-left: 13px;
  color: #757575;
}
.AlertItem .message em {
  font-style: normal;
  background-color: #fff6b0;
  color: #615c4c;
  padding: 3px 0;
}
