.data_tab_link {
  margin-left: 220px;
}

.data_tab_row_description {
  width: 220px;
  float: left;
}

.data_tab_row_value {
  display: inline-block;
}

.data_tap_info {
  margin-top: 10px;
}

.data_tab_row_minimal {
  flex: 1;
  margin-right: 15px;
}
