.App .EmployerAccountsList .table .account-type-main > td.level {
  border-color: #c3eace;
  background: #e2feea;
}
.App .EmployerAccountsList .table .account-type-disabled > td.level {
  border-color: #d5d5d5;
  background: #efefef;
}
.App .EmployerAccountsList .table .account-type-manager > td.level {
  border-color: #c2e4e9;
  background: #e3fbfe;
}
