/* Regular button. All other buttons inherit from this and only need color adjustments. */
/* Since the contents of modal windows fall outside of the <App /> component, we target them separately. */
.Button,
#modals .Button {
  background: white;
  text-shadow: none;
  color: #2a2b2e;
  fill: #2a2b2e;
  height: 35px;
  border: none;
  box-shadow: inset 0 -1.5px 0 #cacbcc, inset 0 0 0 1px #ebeced;
  /* The padding can get overridden inside of an entity list, so we're making it important. */
  padding: 6px 12px !important;
}
/* Buttons inside of a table are slightly shorter due to the cell padding. */
.Button.in-table,
#modals .Button.in-table {
  height: 33px;
}
/* Block button: centered text. */
.Button.block {
  display: block;
  width: 100%;
  text-align: center;
}
.Button:hover,
#modals .Button:hover,
.Button:focus,
#modals .Button:focus {
  /* Hover states are darker, so the edges should be visible. */
  z-index: 18 !important;
  background: #cacbcc;
  color: #2a2b2e;
  fill: #2a2b2e;
  box-shadow: inset 0 -1.5px 0 #a7a7a8, inset 0 0 0 1px #c0c1c2;
}
/* The button's disabled state can be hidden by passing the 'no-disabled' class. */
/* This makes it disabled without having the appearance of being disabled. */
.Button[disabled]:not(.no-disabled),
#modals .Button[disabled]:not(.no-disabled) {
  opacity: 0.4;
  background: #dcdcdc;
  color: #2a2b2e;
  fill: #2a2b2e;
  z-index: 10;
  box-shadow: inset 0 -1.5px 0 #a2a4a5, inset 0 0 0 1px #c5c6c7;
  filter: contrast(80%) grayscale(75%);
}
.Button[disabled].no-disabled,
#modals .Button[disabled].no-disabled {
  opacity: 1;
}
/* The regular button's bright state only changes its disabled style. */
.Button.bright[disabled],
#modals .Button.bright[disabled] {
  opacity: 0.65;
  background: #fff;
  box-shadow: inset 0 -1.5px 0 #cacbcc, inset 0 0 0 1px #ebeced;
  filter: contrast(80%) grayscale(75%);
}
.Button.bright[disabled]:hover,
#modals .Button.bright[disabled]:hover,
.Button.bright[disabled]:focus,
#modals .Button.bright[disabled]:focus {
  background: #cacbcc;
  box-shadow: inset 0 -1.5px 0 #a7a7a8, inset 0 0 0 1px #c0c1c2;
}

/* The pressed down state, used by all buttons (currently). */
/* This is different from the 'active' pseudoclass, which is shown when the user clicks; */
/* this state is used only when a button remaind pressed down for a long time. */
.Button.active,
#modals .Button.active {
  background: #d4d4d4;
  color: #2a2b2e;
  fill: #2a2b2e;
  border: none;
  z-index: 11;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2), inset 0 5px 64px rgba(0, 0, 0, 0.15) !important;
}
/* A slightly modified version for the 'regular' style. */
.Button.regular.active,
#modals .Button.regular.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2) !important;
}
.Button.active:hover,
#modals .Button.active:hover,
.Button.active:focus,
#modals .Button.active:focus {
  background: #cacbcc;
  color: #2a2b2e;
  fill: #2a2b2e;
}

/* A special 'active' state that buttons use when they are clicked. */
/* Currently only the regular button uses it. */
.Button:active,
#modals .Button:active,
.Button:active:hover,
#modals .Button:active:hover {
  background: #934ad2;
  color: white;
  fill: white;
  z-index: 11;
  border: none;
  box-shadow: inset 0 -1.5px 0 #5e3086, inset 0 0 0 1px #753ca7;
}

/* Primary button. */
.Button.primary,
#modals .Button.primary {
  background: #5484f7;
  color: white;
  fill: white;
  z-index: 11;
  box-shadow: inset 0 -1.5px 0 #4064bc, inset 0 0 0 1px #4e7ae4;
}
.Button.primary:hover,
#modals .Button.primary:hover,
.Button.primary:focus,
#modals .Button.primary:focus {
  background: #4064bc;
  color: white;
  fill: white;
  box-shadow: inset 0 -1.5px 0 #324f94, inset 0 0 0 1px #3b5cad;
}
.Button.primary[disabled]:not(.no-disabled),
#modals .Button.primary[disabled]:not(.no-disabled) {
  opacity: 0.4;
  filter: contrast(80%) grayscale(75%);
}
/* Primary bright states. */
.Button.primary.bright,
#modals .Button.primary.bright {
  background: #edf2fe;
  color: #2a2b2e;
  fill: #2a2b2e;
  box-shadow: inset 0 -1.5px 0 #b6c0d6, inset 0 0 0 1px #d4dff8;
}
.Button.primary.bright:hover,
#modals .Button.primary.bright:hover,
.Button.primary.bright:focus,
#modals .Button.primary.bright:focus {
  background: #c2c8d7;
  color: #2a2b2e;
  fill: #2a2b2e;
  box-shadow: inset 0 -1.5px 0 #7d8aa5, inset 0 0 0 1px #a3b2d2;
}
.Button.primary.bright[disabled]:not(.no-disabled),
#modals .Button.primary.bright[disabled]:not(.no-disabled) {
  opacity: 0.65;
  filter: contrast(80%) grayscale(75%);
}

/* Neutral button. */
.Button.neutral,
#modals .Button.neutral {
  background: #9aa1ac;
  color: white;
  fill: white;
  box-shadow: inset 0 -1.5px 0 #7a8490, inset 0 0 0 1px #9199a3;
}
.Button.neutral:hover,
#modals .Button.neutral:hover,
.Button.neutral:focus,
#modals .Button.neutral:focus {
  background: #747981;
  color: white;
  fill: white;
  box-shadow: inset 0 -1.5px 0 #5e646c, inset 0 0 0 1px #6e737a;
}
.Button.neutral[disabled]:not(.no-disabled),
#modals .Button.neutral[disabled]:not(.no-disabled) {
  opacity: 0.4;
  filter: contrast(80%) grayscale(75%);
}
/* Neutral bright states. */
.Button.neutral.bright,
#modals .Button.neutral.bright {
  background: #e6ebef;
  color: #2a2b2e;
  fill: #2a2b2e;
  box-shadow: inset 0 -1.5px 0 #b1b6ba, inset 0 0 0 1px #cdd4da;
}
.Button.neutral.bright:hover,
#modals .Button.neutral.bright:hover,
.Button.neutral.bright:focus,
#modals .Button.neutral.bright:focus {
  background: #a8afb4;
  color: #2a2b2e;
  fill: #2a2b2e;
  box-shadow: inset 0 -1.5px 0 #757a7d, inset 0 0 0 1px #8e959b;
}
.Button.neutral.bright[disabled]:not(.no-disabled),
#modals .Button.neutral.bright[disabled]:not(.no-disabled) {
  opacity: 0.65;
  filter: contrast(80%) grayscale(75%);
}

/* Success button. */
.Button.success,
#modals .Button.success {
  background: #4ccc56;
  color: white;
  fill: white;
  z-index: 11;
  box-shadow: inset 0 -1.5px 0 #3a9e44, inset 0 0 0 1px #47be53;
}
.Button.success:hover,
#modals .Button.success:hover,
.Button.success:focus,
#modals .Button.success:focus {
  background: #399a41;
  color: white;
  fill: white;
  box-shadow: inset 0 -1.5px 0 #297a34, inset 0 0 0 1px #308f3d;
}
.Button.success[disabled]:not(.no-disabled),
#modals .Button.success[disabled]:not(.no-disabled) {
  opacity: 0.4;
  filter: contrast(80%) grayscale(75%);
}
/* Success bright states. */
.Button.success.bright,
#modals .Button.success.bright {
  background: #e5ffde;
  color: #2a2b2e;
  fill: #2a2b2e;
  box-shadow: inset 0 -1.5px 0 #acd1a4, inset 0 0 0 1px #c8f3bf;
}
.Button.success.bright:hover,
#modals .Button.success.bright:hover,
.Button.success.bright:focus,
#modals .Button.success.bright:focus {
  background: #b9d9b1;
  color: #2a2b2e;
  fill: #2a2b2e;
  box-shadow: inset 0 -1.5px 0 #719f66, inset 0 0 0 1px #94ca8a;
}
.Button.success.bright[disabled]:not(.no-disabled),
#modals .Button.success.bright[disabled]:not(.no-disabled) {
  opacity: 0.65;
  filter: contrast(80%) grayscale(75%);
}

/* Warning button. */
.Button.warning,
#modals .Button.warning {
  background: #f6c956;
  color: white;
  fill: white;
  z-index: 11;
  box-shadow: inset 0 -1.5px 0 #d4a734, inset 0 0 0 1px #edc04d;
}
.Button.warning:hover,
#modals .Button.warning:hover,
.Button.warning:focus,
#modals .Button.warning:focus {
  background: #cea12e;
  color: white;
  fill: white;
  box-shadow: inset 0 -1.5px 0 #a47704, inset 0 0 0 1px #c29522;
}
.Button.warning[disabled]:not(.no-disabled),
#modals .Button.warning[disabled]:not(.no-disabled) {
  opacity: 0.4;
  filter: contrast(80%) grayscale(75%);
}
/* Warning bright states. */
.Button.warning.bright,
#modals .Button.warning.bright {
  background: #ffffdb;
  color: #2a2b2e;
  fill: #2a2b2e;
  box-shadow: inset 0 -1.5px 0 #cccc9f, inset 0 0 0 1px #eeb;
}
.Button.warning.bright:hover,
#modals .Button.warning.bright:hover,
.Button.warning.bright:focus,
#modals .Button.warning.bright:focus {
  background: #d9d9ac;
  color: #2a2b2e;
  fill: #2a2b2e;
  box-shadow: inset 0 -1.5px 0 #9a9a61, inset 0 0 0 1px #c3c384;
}
.Button.warning.bright[disabled]:not(.no-disabled),
#modals .Button.warning.bright[disabled]:not(.no-disabled) {
  opacity: 0.65;
  filter: contrast(80%) grayscale(75%);
}

/* Danger button. */
.Button.danger,
#modals .Button.danger {
  background: #ff5252;
  color: white;
  fill: white;
  z-index: 11;
  box-shadow: inset 0 -1.5px 0 #c23e3e, inset 0 0 0 1px #eb4c4c;
}
.Button.danger:hover,
#modals .Button.danger:hover,
.Button.danger:focus,
#modals .Button.danger:focus {
  background: #c23e3e;
  color: white;
  fill: white;
  box-shadow: inset 0 -1.5px 0 #993131, inset 0 0 0 1px #b33939;
}
.Button.danger[disabled]:not(.no-disabled),
#modals .Button.danger[disabled]:not(.no-disabled) {
  opacity: 0.4;
  filter: contrast(80%) grayscale(75%);
}
/* Danger bright states. */
.Button.danger.bright,
#modals .Button.danger.bright {
  background: #ffeded;
  color: #2a2b2e;
  fill: #2a2b2e;
  box-shadow: inset 0 -1.5px 0 #d8b6b7, inset 0 0 0 1px #fbd4d5;
}
.Button.danger.bright:hover,
#modals .Button.danger.bright:hover,
.Button.danger.bright:focus,
#modals .Button.danger.bright:focus {
  background: #d9c2c2;
  color: #2a2b2e;
  fill: #2a2b2e;
  box-shadow: inset 0 -1.5px 0 #a87d7f, inset 0 0 0 1px #d5a3a4;
}
.Button.danger.bright[disabled]:not(.no-disabled),
#modals .Button.danger.bright[disabled]:not(.no-disabled) {
  opacity: 0.65;
  filter: contrast(80%) grayscale(75%);
}

/* The 'highlighted' button displays in purple, as the 'active' state. */
/* TODO: make a hover style. */
/* TODO: make a bright style. */
.Button.highlighted,
#modals .Button.highlighted {
  background: #934ad2;
  color: white;
  fill: white;
  z-index: 20;
  box-shadow: inset 0 -1.5px 0 #5e3086, inset 0 0 0 1px #753ca7;
}
.Button.highlighted:hover,
#modals .Button.highlighted:hover,
.Button.highlighted:focus,
#modals .Button.highlighted:focus {
  background: #934ad2;
  color: white;
  fill: white;
  z-index: 22;
  box-shadow: inset 0 -1.5px 0 #5e3086, inset 0 0 0 1px #753ca7;
}
.Button.highlighted[disabled]:not(.no-disabled),
#modals .Button.highlighted[disabled]:not(.no-disabled) {
  opacity: 0.4;
  filter: contrast(80%) grayscale(75%);
}
.Button.highlighted.bright,
#modals .Button.highlighted.bright {
  background: #934ad2;
  color: white;
  fill: white;
  box-shadow: inset 0 -1.5px 0 #5e3086, inset 0 0 0 1px #753ca7;
}
.Button.highlighted.bright:hover,
#modals .Button.highlighted.bright:hover,
.Button.highlighted.bright:focus,
#modals .Button.highlighted.bright:focus {
  background: #934ad2;
  color: white;
  fill: white;
  box-shadow: inset 0 -1.5px 0 #5e3086, inset 0 0 0 1px #753ca7;
}
.Button.highlighted.bright[disabled]:not(.no-disabled),
#modals .Button.highlighted.bright[disabled]:not(.no-disabled) {
  opacity: 0.65;
  filter: contrast(80%) grayscale(75%);
}

/* Button link - a regular link that looks like a button. */
a.ButtonLink,
#modals a.ButtonLink {
  /* The line height is slightly different if it's not a <Button>. */
  line-height: 22px;
}
a.ButtonLink,
#modals a.ButtonLink,
a.ButtonLink:hover,
#modals a.ButtonLink:hover,
a.ButtonLink:focus,
#modals a.ButtonLink:focus {
  /* FIXME: The color can be overridden inside an entity list, so... */
  color: #2a2b2e !important;
  fill: #2a2b2e !important;
}
a.ButtonLink:active,
#modals a.ButtonLink:active,
a.ButtonLink.primary,
#modals a.ButtonLink.primary,
a.ButtonLink.primary:hover,
#modals a.ButtonLink.primary:hover,
a.ButtonLink.primary:focus,
#modals a.ButtonLink.primary:focus,
a.ButtonLink.highlighted,
#modals a.ButtonLink.highlighted,
a.ButtonLink.highlighted:hover,
#modals a.ButtonLink.highlighted:hover,
a.ButtonLink.highlighted:focus,
#modals a.ButtonLink.highlighted:focus,
a.ButtonLink.success,
#modals a.ButtonLink.success,
a.ButtonLink.success:hover,
#modals a.ButtonLink.success:hover,
a.ButtonLink.success:focus,
#modals a.ButtonLink.success:focus,
a.ButtonLink.warning,
#modals a.ButtonLink.warning,
a.ButtonLink.warning:hover,
#modals a.ButtonLink.warning:hover,
a.ButtonLink.warning:focus,
#modals a.ButtonLink.warning:focus,
a.ButtonLink.danger,
#modals a.ButtonLink.danger,
a.ButtonLink.danger:hover,
#modals a.ButtonLink.danger:hover,
a.ButtonLink.danger:focus,
#modals a.ButtonLink.danger:focus {
  /* FIXME: And the override back to white. */
  color: white !important;
  fill: white !important;
}

/* Boolean button - a button that as a checkbox inside. */
.ButtonBoolean .checkbox {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  vertical-align: middle;
  position: relative;
  width: 20px;
  cursor: pointer;
}
.ButtonBoolean .checkbox label {
  height: 18px;
  min-height: 18px;
  vertical-align: top;
  margin: 0;
  padding-right: 5px;
  padding-left: 0;
  width: 20px;
}
.ButtonBoolean .checkbox label input {
  margin: 0;
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: -1px;
  left: 0;
  right: 0;
  width: 15px;
  height: 15px;
}
.ButtonBoolean.checked,
#modals .ButtonBoolean.checked {
  z-index: 10;
  background-color: #edf2fe;
  box-shadow: inset 0 -1.5px 0 #b6c0d6, inset 0 0 0 1px #d4dff8;
}
.ButtonBoolean.checked:hover,
#modals .ButtonBoolean.checked:hover,
.ButtonBoolean.checked:focus,
#modals .ButtonBoolean.checked:focus {
  background-color: #c2c8d7;
  box-shadow: inset 0 -1.5px 0 #7d8aa5, inset 0 0 0 1px #a3b2d2;
}

/* ButtonIcon - a button with an icon inside. */
.ButtonIcon,
#modals .ButtonIcon {
  display: flex;
}
.ButtonIcon .icon,
#modals .ButtonIcon .icon {
  display: flex;
  align-self: center;
}

/* ButtonDropdown - a dropdown menu with menu items. */
.ButtonDropdown,
#modals .ButtonDropdown {
}

/* MenuItemBoolean - regular dropdown menu item. */
.MenuItem > a,
#modals .MenuItem > a {
  /* Note: this allows the <a> to have a background color without interfering with the borders. */
  margin-left: 1px;
  margin-right: 1px;
  padding-left: 18px;
  padding-right: 18px;
}

/* MenuItemBoolean - menu item with the option to display a checkmark. */
.MenuItemBoolean,
#modals .MenuItemBoolean {
  position: relative;
}
.MenuItemBoolean .icon,
#modals .MenuItemBoolean .icon {
  position: absolute;
  left: 6px;
  top: 5px;
}
.MenuItemBoolean .icon .img,
#modals .MenuItemBoolean .icon .img {
  width: 11px;
}
.MenuItemBoolean:hover .icon,
#modals .MenuItemBoolean:hover .icon,
.MenuItemBoolean:focus .icon,
#modals .MenuItemBoolean:focus .icon {
  fill: white;
}

/* Block buttons. */
.Button.block,
#modals .Button.block {
  display: block;
  width: 100%;
}
