.listing-shift-tabs {
  min-height: 900px;
}

.listing-shift-tabs .nav-tabs {
  border-bottom: 0;
}

.listing-shift-tabs .padded-bottom {
  padding-bottom: 20px;
}
