.DataFileUpload {
  display: flex;
}
.DataFileUpload .image-tooltip {
  background: rgba(0, 0, 0, 0.9);
  opacity: 1;
}
.DataFileUpload .main {
  display: flex;
}
.DataFileUpload .main .current-avatar {
  margin-right: 4px;
  float: none;
}
.DataFileUpload .main .uploader {
}
.DataFileUpload .main .view,
.DataFileUpload .main .delete {
  margin-left: 4px;
}
.DataFileUpload .feedback {
  display: flex;
  padding: 4px 13px 2px 13px;
  color: #757575;
  fill: #757575;
}
.DataFileUpload .feedback .error {
  color: red;
}
.DataFileUpload .feedback .hidden {
  display: none;
}
.DataFileUpload .feedback .LoadingIndicator {
  width: 24px;
  height: 24px;
  padding: 0;
}
