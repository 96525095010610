.App .missing-time-modal .item-form-label,
#modals .missing-time-modal .item-form-label {
  padding-right: 40px;
  padding-left: 4px;
}
.App .missing-time-modal .item-form-control,
#modals .missing-time-modal .item-form-control {
  padding-bottom: 4px;
}
.App .missing-time-modal .item-form-help,
#modals .missing-time-modal .item-form-help {
  padding-left: 4px;
}
.App .missing-time-modal .job-info,
#modals .missing-time-modal .job-info {
  width: 100%;
}
.App .missing-time-modal .tiny-headers th,
#modals .missing-time-modal .tiny-headers th {
  white-space: nowrap;
  width: 1%;
}

.App .missing-time-modal .bottom-help,
#modals .missing-time-modal .bottom-help {
  margin-bottom: 0;
  margin-top: 10px;
}
