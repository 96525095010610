.entity-list-subtabs > .nav-tabs {
  background: #f9f9fa;
  padding-top: 5px;
  padding-left: 5px;
  border: 1px solid #ddd;
  border-bottom: 0;
}
.entity-list-subtabs.no-border > .nav-tabs {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid #ddd;
}
