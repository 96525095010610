table.simpletable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  margin: 0;
}

/* Basic styling for all cells. */
table.simpletable tr th,
table.simpletable tr td {
  line-height: 1.5;
  padding: 9px 11px;
  background: white;
  font-weight: normal;
  /* Note: 0px by default; enabled using rowBorders and colBorders. */
  border: 0px solid #e3e3e3;
}

/* Set up the styling for the table header cells and regular cells. */
table.simpletable tr th {
  color: #757575;
}
table.simpletable tr td {
  color: #1f2022;
}

/* Option: row borders. */
table.simpletable.hasRowBorders thead tr th,
table.simpletable.hasRowBorders thead tr td,
table.simpletable.hasRowBorders tbody tr th,
table.simpletable.hasRowBorders tbody tr td,
table.simpletable.hasRowBorders tfoot tr th,
table.simpletable.hasRowBorders tfoot tr td {
  border-bottom-width: 1px;
}
/* Ensure we don't enable the far bottom border if the user hasn't specified hasBottomBorder. */
table.simpletable.hasRowBorders.hasNoBottomBorder thead:last-child tr:last-child th,
table.simpletable.hasRowBorders.hasNoBottomBorder thead:last-child tr:last-child td,
table.simpletable.hasRowBorders.hasNoBottomBorder tbody:last-child tr:last-child th,
table.simpletable.hasRowBorders.hasNoBottomBorder tbody:last-child tr:last-child td,
table.simpletable.hasRowBorders.hasNoBottomBorder tfoot:last-child tr:last-child th,
table.simpletable.hasRowBorders.hasNoBottomBorder tfoot:last-child tr:last-child td {
  border-bottom-width: 0;
}
/* Option: column borders. */
table.simpletable.hasColBorders thead tr th,
table.simpletable.hasColBorders thead tr td,
table.simpletable.hasColBorders tbody tr th,
table.simpletable.hasColBorders tbody tr td,
table.simpletable.hasColBorders tfoot tr th,
table.simpletable.hasColBorders tfoot tr td {
  border-right-width: 1px;
}
/* As with the far bottom border, reset the far right border to zero if the user hasn't specified hasRightBorder. */
table.simpletable.hasRowBorders.hasNoRightBorder tr th:last-child,
table.simpletable.hasRowBorders.hasNoRightBorder tr td:last-child {
  border-right-width: 0;
}

/* Option: margin to separate the table from sibling elements. */
table.simpletable.hasTopMargin {
  margin-top: 15px;
}
table.simpletable.hasBottomMargin {
  margin-bottom: 15px;
}
/* Option: top outer border, for non-integrated lists. */
table.simpletable.hasTopBorder.isNotIntegrated thead:first-child tr:first-child th,
table.simpletable.hasTopBorder.isNotIntegrated thead:first-child tr:first-child td,
table.simpletable.hasTopBorder.isNotIntegrated tbody:first-child tr:first-child th,
table.simpletable.hasTopBorder.isNotIntegrated tbody:first-child tr:first-child td,
table.simpletable.hasTopBorder.isNotIntegrated tfoot:first-child tr:first-child th,
table.simpletable.hasTopBorder.isNotIntegrated tfoot:first-child tr:first-child td {
  border-top-width: 1px;
}
/* Option: bottom outer border. */
table.simpletable.hasBottomBorder thead:last-child tr:last-child th,
table.simpletable.hasBottomBorder thead:last-child tr:last-child td,
table.simpletable.hasBottomBorder tbody:last-child tr:last-child th,
table.simpletable.hasBottomBorder tbody:last-child tr:last-child td,
table.simpletable.hasBottomBorder tfoot:last-child tr:last-child th,
table.simpletable.hasBottomBorder tfoot:last-child tr:last-child td {
  border-bottom-width: 1px;
}
/* Option: left outer border. NOTE: the noLeftBorder class is a fix for table layouts with colSpan. */
table.simpletable.hasLeftBorder tr:not(.noLeftBorder) th:first-child,
table.simpletable.hasLeftBorder tr:not(.noLeftBorder) td:first-child {
  border-left-width: 1px;
}
/* Option: right outer border. */
table.simpletable.hasRightBorder tr th:last-child,
table.simpletable.hasRightBorder tr td:last-child {
  border-right-width: 1px;
}
/* Option: top left rounding. */
table.simpletable.hasTopLeftRounding thead:first-child tr:first-child td:first-child,
table.simpletable.hasTopLeftRounding thead:first-child tr:first-child th:first-child,
table.simpletable.hasTopLeftRounding tbody:first-child tr:first-child td:first-child,
table.simpletable.hasTopLeftRounding tbody:first-child tr:first-child th:first-child,
table.simpletable.hasTopLeftRounding tfoot:first-child tr:first-child td:first-child,
table.simpletable.hasTopLeftRounding tfoot:first-child tr:first-child th:first-child {
  border-top-left-radius: 3px;
}
/* Option: top right rounding. */
table.simpletable.hasTopRightRounding thead:first-child tr:first-child td:last-child,
table.simpletable.hasTopRightRounding thead:first-child tr:first-child th:last-child,
table.simpletable.hasTopRightRounding tbody:first-child tr:first-child td:last-child,
table.simpletable.hasTopRightRounding tbody:first-child tr:first-child th:last-child,
table.simpletable.hasTopRightRounding tfoot:first-child tr:first-child td:last-child,
table.simpletable.hasTopRightRounding tfoot:first-child tr:first-child th:last-child {
  border-top-right-radius: 3px;
}
/* Option: bottom left rounding. */
table.simpletable.hasBottomLeftRounding thead:last-child tr:last-child td:first-child,
table.simpletable.hasBottomLeftRounding thead:last-child tr:last-child th:first-child,
table.simpletable.hasBottomLeftRounding tbody:last-child tr:last-child td:first-child,
table.simpletable.hasBottomLeftRounding tbody:last-child tr:last-child th:first-child,
table.simpletable.hasBottomLeftRounding tfoot:last-child tr:last-child td:first-child,
table.simpletable.hasBottomLeftRounding tfoot:last-child tr:last-child th:first-child {
  border-bottom-left-radius: 3px;
}
/* Option: bottom right rounding. */
table.simpletable.hasBottomRightRounding thead:last-child tr:last-child td:last-child,
table.simpletable.hasBottomRightRounding thead:last-child tr:last-child th:last-child,
table.simpletable.hasBottomRightRounding tbody:last-child tr:last-child td:last-child,
table.simpletable.hasBottomRightRounding tbody:last-child tr:last-child th:last-child,
table.simpletable.hasBottomRightRounding tfoot:last-child tr:last-child td:last-child,
table.simpletable.hasBottomRightRounding tfoot:last-child tr:last-child th:last-child {
  border-bottom-right-radius: 3px;
}
