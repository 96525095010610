.App .entity-detail-item .RecordName {
  position: relative;
}
.App .entity-detail-item .RecordName .user-data,
.App .entity-detail-item .RecordName .user-data-bio,
.App .entity-detail-item .RecordName .user-email {
  margin: 0;
}
.App .entity-detail-item .RecordName.has-avatar .user-data,
.App .entity-detail-item .RecordName.has-avatar .user-data-bio,
.App .entity-detail-item .RecordName.has-avatar .user-email {
  margin-left: 82px;
}
.App .entity-detail-item .RecordName .user-email {
  font-size: 20px;
  line-height: 23px;
  color: black;
}
.App.acp-theme-dark .entity-detail-item .RecordName .user-email {
  color: white;
}
.App .entity-detail-item .RecordName .user-data-bio {
  margin-top: 2px;
  margin-bottom: 6px;
}
.App .entity-detail-item .RecordName .loading-container {
  height: 48px;
  padding-top: 11px;
  padding-left: 60px;
  position: absolute;
  top: 50%;
  margin-top: -2px;
  left: 25px;
}
/* Fix the container's height in case we have no data. */
.App .entity-detail-item .record-name-segment {
  min-height: 107px;
}
