/* Prevent the colors from being overridden by an entity list row hover. */
.App table.entity-list tr.clickable:hover td .bootstrap-typeahead .dropdown-menu > li a {
  color: #1f2022;
}

.App table.entity-list tr.clickable:hover td .bootstrap-typeahead .dropdown-menu > li a:hover,
.bootstrap-typeahead .dropdown-menu > li a:hover {
  color: white;
  background: #ff5252;
  box-shadow: none !important;
}

.App .FilterAutocompleteClear,
#modals .FilterAutocompleteClear {
  position: absolute;
  top: 7px;
  right: 7px;
  text-indent: -9999px;
  width: 20px;
  height: 20px;
  background: #55555520;
  border-radius: 3px;
}
.App .FilterAutocompleteClear::after,
#modals .FilterAutocompleteClear::after {
  content: '×';
  color: #737373;
  text-indent: 0;
  text-align: center;
  width: 21px;
  height: 20px;
  font-size: 23px;
  line-height: 17px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.App .FilterAutocompleteClear:hover,
#modals .FilterAutocompleteClear:hover {
  background: #55555533;
}

/* Prevent the hint from displaying outside the input field. */
.App .FilterAutocomplete .rbt-input-hint-container {
  overflow: hidden;
}
/* Make the padding the same as for input fields. */
.App .FilterAutocomplete .rbt-menu.dropdown-menu .dropdown-item {
  padding-left: 12px;
  padding-right: 12px;
}
