#modals .modal-window {
}
#modals .modal-window .modal-dialog .modal-content {
  border-radius: 3px;
  text-align: left;
}
#modals .modal-window .modal-dialog .modal-content .modal-header {
  padding: 11px 20px 10px;
}
#modals .modal-window .modal-dialog .modal-content .modal-header button.close {
  background: none;
  box-shadow: none;
}
#modals .modal-window .modal-dialog .modal-content .modal-header .modal-title {
  font-size: 15px;
  line-height: 20px;
  color: black;
}
#modals .modal-window .modal-dialog .modal-content .modal-body {
  padding: 15px 20px 22px;
}
#modals .modal-window .modal-dialog .modal-content .modal-body p:first-child {
  margin-top: 7px;
}
#modals .modal-window .modal-dialog .modal-content .modal-body p:last-child {
  margin-bottom: 0;
}
#modals .modal-window .modal-dialog .modal-content .modal-body .control-label {
  font-weight: 500;
  color: #1f2022;
}
#modals .modal-window .modal-dialog .modal-content .modal-footer .btn + .btn {
  margin-left: 4px;
}

#modals .modal-window .modal-dialog .modal-content .modal-footer {
  text-align: left;
  display: flex;
}
#modals .modal-window .modal-dialog .modal-content .modal-footer .modal-error {
  padding-left: 5px;
  margin: 0;
  align-self: center;
  line-height: 17px;
  padding-right: 20px;
}
#modals .modal-window .modal-dialog .modal-content .modal-footer .buttons {
  text-align: right;
  white-space: nowrap;
  flex: 1;
}

#modals .modal-window .modal-dialog .modal-content .modal-body + .modal-body {
  border-top: 1px solid #e5e5e5;
}
#modals .modal-window .modal-dialog .modal-content .modal-body.text-section {
  padding: 8px 20px 8px;
}

#modals .card-rate-dependency-modal .modal-dialog {
  min-width: 930px;
}

#modals .wide .modal-dialog {
  min-width: 930px;
}

#modals .modal-window .table-wrapper {
  overflow: auto;
  max-height: 900px;
}

#modals .card-rate-dependency-modal .modal-dialog .modal-body .App {
  min-height: auto;
}
