$height: 23px;
$width: 92px;
$padding: 1em;

.input {
  position: relative;
  width: $width;
  height: $height;
  font-weight: normal;

  &:before {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: $padding;
    height: 100%;
    content: '£';
  }

  &--usd {
    &:before {
      content: '$';
    }
  }

  &__field {
    width: $width;
    height: $height;
    padding: 0 0 0 $padding !important;
    border-radius: 2px;
    appearance: none;
    border: 1px solid #cccccc;
  }

  &:hover {
    b {
      display: block;
    }
  }

  b {
    display: none;
  }
}
