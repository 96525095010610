.DocumentAttachment .attachment-table td,
.DocumentAttachment .attachment-table th {
  border-radius: 0 !important;
}
.DocumentAttachment.isDeleted .attachment-table tr:nth-child(2) td:last-child {
  border-top-right-radius: 3px !important;
}
.DocumentAttachment.isDeleted .attachment-table tr:last-child td:last-child {
  border-bottom-right-radius: 3px !important;
}
/* We need to force override the regular radius settings,
since they don't work when you have rowSpan. */
.DocumentAttachment .attachment-table td.idx {
  min-width: 28px;
  width: 1%;
  text-align: left;
  white-space: nowrap;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  border-right-width: 0 !important;
}
.DocumentAttachment .attachment-table th.header {
  width: 1%;
  white-space: nowrap;
}
.DocumentAttachment .attachment-table td.btns {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  width: 84px;
}
.DocumentAttachment .attachment-table .top {
  border-top-width: 1px;
}
.DocumentAttachment .attachment-table td.btns .Button.view {
  margin-bottom: 8px;
}
