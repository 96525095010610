/* Note: keep in mind we use td.user-line as well. It should not get this styling. */
.App span.user-line,
#modals span.user-line {
  line-height: 19px;
  padding-right: 21px;
  white-space: nowrap;
  display: block;
}

.App .user-line .avatar-wrapper,
#modals .user-line .avatar-wrapper {
  margin-right: 5px;
}

.App .user-line .platform-name {
  padding: 2px;
  margin: 7px;
  left: 50%;
  text-align: center;
  width: 35px;
  text-indent: 0.5px;
  background-color: #e3e3e3;
}
