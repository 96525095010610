.venueAutocomplete {
  min-width: 300px;
}

.venueAutocomplete .form-control {
	background: white url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='13' height='13' class='bi bi-chevron-down' viewBox='0 0 16 16'><path d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>"
		) no-repeat;
	background-position: right 9px top 50%;
}

.venueAutocomplete .dropdown-menu {
  display: none;
  width: 300px !important;
}

.venueAutocomplete .rbt-aux{
  align-items: center;
  display: flex;
  bottom: 0;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
  z-index: 1000;
}

.venueAutocomplete .rbt-aux .rbt-close {
  margin-top: -4px;
  pointer-events: auto;
  background-color: white;
  opacity: 70%;
}
