.multipleFiles {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  p {
    flex: 3;
  }
  select {
    flex: 2;
  }
  input {
    flex: 1;
  }
}
