.App .login-container {
  width: 455px;
}

.App .login-main-logo {
  margin-bottom: 10px;
  margin-top: 100px;
  margin-left: -25px;
}

.App .section-link {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .App .login-container {
    width: 293px;
  }
  .App .login-main-logo {
    margin-bottom: 10px;
    width: 292px;
  }
}

.App .login-field {
  margin-top: 40px;
}

.App .login-trouble-section {
  margin-top: 40px;
}
