.App .login-container .login {
  min-height: 720px;
}
.App .login-container .login .section .email {
  margin-top: 40px;
}

.App .login-container .login .section .password {
  margin-top: 25px;
}

.App .login-container .login .section .login-buttons {
  margin-top: 25px;
  margin-bottom: 40px;
}

.App .login-container .login .section .login-buttons .forgotten-password {
  float: right;
}

.App .login-container .login .section.secondary {
  margin-top: 40px;
}

.App .login-container .login .feedback-message {
  display: block;
  position: relative;
  top: 2px;
  line-height: 25px;
  font-size: 20px;
  color: #bd2128;
  margin: 0;
  padding: 6px 0 0;
}

.App .login-container .login .feedback-message a {
  color: #bd2128;
  text-decoration: underline;
}
