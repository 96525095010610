.App .acp-result-count {
  text-align: left;
  margin-left: 2px;
  margin-bottom: 4px;
}
.App .acp-result-count.isIntegrated {
  margin: 0;
}
.App .acp-result-count.withInfoColumn {
  margin-left: 30px;
}
