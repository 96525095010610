.questionIcon {
  margin: 5px;
  border-radius: 18px;
  height: 19px;
  background: #f2d63f;
}

.questionIcon svg {
  margin-top: -1px;
}
