.App .NameAvatar,
#modals .NameAvatar {
  position: relative;
  display: inline-block;
}
.App .NameAvatar.with-avatar,
#modals .NameAvatar.with-avatar {
  padding-left: 29px;
}
/* If we have an avatar but no name, we'll add a non-breaking space for the correct line height.
 * Then we move the avatar back over that empty space to hide it. */
.App .NameAvatar.with-avatar.with-nbsp,
#modals .NameAvatar.with-avatar.with-nbsp {
  padding-left: 25px;
}
.App .NameAvatar.nowrap .username,
#modals .NameAvatar.nowrap .username {
  white-space: nowrap;
}
