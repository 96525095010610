.AddWorkerToBlocklistModal {
  display: flex;
  flex-direction: row;
}
.AddWorkerToBlocklistModal .section {
  flex: 1 1 0;
}
.AddWorkerToBlocklistModal .explanation {
  margin-top: 8px;
}
