.acp-actions-generic-component {
  display: flex;
  flex: 1;
}

.filter-component-wrapper {
  &--inline {
    display: inline !important;
  }

  &--max-width {
    width: 100%;
  }
}
