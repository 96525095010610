.FormError {
  margin-top: 6px;
  display: inline-block;
}
.FormError.type-error {
  color: #ea3a3a;
}
.FormError.type-warning {
  color: #d39f00;
}
