.App .filter-group {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  margin-bottom: 10px;
}

.App .filter-group .button-group-inputs {
  display: block;
}
