.copyable-text {
  position: relative;
  height: 100%;
}
.copyable-text .copy-button {
  position: absolute;
  top: 1px;
  right: 1px;
  cursor: pointer;
  z-index: 100;
  border-radius: 3px;
  display: inline-block;
  /* To override the Button defaults. */
  height: 35px !important;
}
.copyable-text .copy-button.no-float {
  position: relative;
  top: 0;
  left: 0;
}
.copyable-text .copy-button .icon {
  vertical-align: sub;
  position: relative;
  left: 1px;
  top: 1px;
}
.copyable-text.hover-hidden .copy-button {
  display: none;
}
/* Little extra hack for entity lists. */
.entity-list tr td a:hover .copyable-text.hover-hidden .copy-button,
.copyable-text.hover-hidden:hover .copy-button {
  display: inline-block;
}
