.App .entity-detail-controls {
  padding: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.App .entity-detail-controls .spaces {
  flex: 1;
}
.App .entity-detail-controls .right {
  margin-left: auto;
}

.App .entity-detail-controls.overlayed {
  /* Note: this is deprecated and should not be used in favor of Acp.EntityList. */
  position: absolute;
  right: 23px;
  padding-top: 7px;
  z-index: 5;
}
.App .entity-detail-controls .input-group {
}
.App .entity-detail-controls .input-group .form-control {
  height: 32px;
}
.App .entity-detail-controls .input-group .input-group-btn {
}
