.acp-wrapper .acp-record-segment {
  border-bottom: 1px solid #e3e3e3;
  padding: 20px;
}
.acp-wrapper .acp-record-segment:last-child {
  border-bottom: 0;
}
.acp-wrapper .acp-record-segment.with-text {
  padding: 9px 11px;
}
.acp-wrapper .acp-record-segment.with-text p:last-child {
  margin-bottom: 0;
}
.acp-wrapper .acp-record-segment.with-table {
  padding: 0;
}
.acp-wrapper .acp-record-segment.with-table > table.editable-table > tbody > tr > th {
  padding-left: 20px;
  width: 192px;
  vertical-align: top;
  line-height: 38px;
}
.acp-wrapper .acp-record-segment.with-table > table.editable-table > tbody > tr > td {
  vertical-align: top;
  padding-top: 1px;
}
.acp-wrapper .acp-record-segment.with-table > table.editable-table > tbody > tr > td.field.middle,
.acp-wrapper .acp-record-segment.with-table > table.editable-table > tbody > tr > th.field.middle {
  vertical-align: middle;
}
.acp-wrapper .acp-record-segment.with-table > table.editable-table > tbody > tr > td:last-child {
  padding-right: 20px;
}
.acp-wrapper .acp-record-segment.with-table > table.editable-table {
  margin-bottom: 0;
}
.acp-wrapper .acp-record-segment.with-table + .acp-record-segment {
}

.acp-wrapper .acp-record-segment.no-padding {
  padding: 0;
}

.acp-wrapper .acp-record-segment.footer {
  padding: 9px 11px;
}

.acp-wrapper .acp-record-segment.no-border {
  border-top: 0;
}

.App.acp-theme-dark .acp-wrapper .acp-record-segment {
  border-color: #38444d;
}
