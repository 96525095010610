@import 'variables';
.acp-table {
  position: relative;

  .simpletable {
    // Ensure we always start off with a blank slate.
    thead,
    tbody,
    tfoot {
      tr th,
      tr td {
        background-color: white;
        padding: 0;
        font-weight: normal;
        text-decoration: none;
      }
    }
    tbody {
      // The 'ctarget' (color target) is a node that can get a colored background.
      .acp-ctarget {
        position: relative;

        // It includes a pseudo-element that acts as an extra top line.
        // This allows the row above the colored row to end with the correct color.
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: -1px;
          left: -1px;
          right: -1px;
          height: 0;
          border-top: 1px solid transparent;

          &:first-child {
            left: 0;
          }
          &:last-child {
            right: 0;
          }
        }
      }
    }
    // Make sure headers don't get too small.
    // By default, never wrap the text either.
    thead {
      tr th {
        min-width: 30px;

        // Allow wrapping for these headers.
        &.allow-wrapping {
          white-space: normal;
        }
      }
    }
    // Don't add a border to the footer.
    tfoot {
      tr th.acp-table-footer {
        border-bottom: 0 !important;
      }
    }

    th,
    td {
      // Default padding for text content.
      span.text-content {
        padding: $cellPaddingAll;
      }
      // These are a number of standard classes added to <th> and <td> elements.
      // 'is-minimal' makes the whole column as small as possible.
      &.is-minimal {
        width: 1%;
      }
      // 'is-main' is used to grow a column to the maximum size; for the most important data.
      &.is-main {
        width: 100%;
      }
      // 'is-numeric' right-aligns the contents.
      &.is-numeric {
        text-align: right;
      }
      // 'has-left-border' left border the contents.
      &.has-left-border {
        border-left-width: 1px;
      }
      // 'is-tags' makes sure tags wrap correctly inside cells
      &.is-tags {
        max-width: 150px;

        & > .acp-table-cell-container {
          white-space: break-spaces;
        }
      }
      // Custom alignment settings.
      &.align-left {
        text-align: left;
      }
      &.align-center {
        text-align: center;
      }
      &.align-right {
        text-align: right;
      }
      &.align-justify {
        text-align: justify;
      }
      &.acp-table-none-border {
        border: none;
        background: transparent;
      }
    }
    td {
      &.is-minimal {
        width: 1%;
        white-space: nowrap;
      }
    }
  }

  // Center the 'no data' message.
  &.has-no-data {
    .simpletable {
      tbody td {
        text-align: center;
        color: #1f2022;
      }
    }
  }
}

/*
Color values for rows and cells; the following colors are supported.

    - none (defaults to white)
    - red
    - orange
    - yellow
    - green
    - cyan
    - blue
    - purple
    - gray

When setting a color value for a row, the color applies only to main rows and not subrows.
Subrows need to have their color indicated separately.

Each color also has a 'priority', which determines which color displays its border
when there is an overlap. Generally the darker color will have higher priority.
*/

$colors: (
  none: (
    // 'None' is the default look of the rows.
    // This can also be used to revert a single cell back to the default
    // inside a colored row.
    priority: 10,
    normalText: #1f2022,
    normalBackground: #fff,
    normalBorder: #e3e3e3,
    hoverText: #236dab,
    hoverBackground: #f3f8fb,
    hoverBorder: #e3e3e3,
    hoverInputText: #236dab,
  ),
  red: (
    priority: 90,
    normalText: #1f2022,
    normalBackground: #ffeded,
    normalBorder: #eed6d6,
    hoverText: #920a0a,
    hoverBackground: #fcd1d1,
    hoverBorder: #e0bebe,
  ),
  orange: (
    priority: 40,
    normalText: #1f2022,
    normalBackground: #fff6e4,
    normalBorder: #eadfc6,
    hoverText: #82300e,
    hoverBackground: #fce8bc,
    hoverBorder: #e0d1a9,
  ),
  yellow: (
    priority: 30,
    normalText: #1f2022,
    normalBackground: #ffffdb,
    normalBorder: #e5e7b6,
    hoverText: #715612,
    hoverBackground: #fcffa6,
    hoverBorder: #e0e394,
  ),
  green: (
    priority: 50,
    normalText: #1f2022,
    normalBackground: #e5ffde,
    normalBorder: #caeec1,
    hoverText: #295f1d,
    hoverBackground: #bfffae,
    hoverBorder: #aae39b,
  ),
  cyan: (
    priority: 60,
    normalText: #1f2022,
    normalBackground: #e3f9ff,
    normalBorder: #c4e2ec,
    hoverText: #394f58,
    hoverBackground: #ccebfe,
    hoverBorder: #b4daec,
  ),
  blue: (
    priority: 70,
    normalText: #1f2022,
    normalBackground: #dae8ff,
    normalBorder: #bfd0ee,
    hoverText: #37405b,
    hoverBackground: #c4d4fd,
    hoverBorder: #aec1ec,
  ),
  purple: (
    priority: 80,
    normalText: #1f2022,
    normalBackground: #eee6fe,
    normalBorder: #d5d0f1,
    hoverText: #4c445c,
    hoverBackground: #e2cffa,
    hoverBorder: #c4b7df,
  ),
  gray: (
    priority: 70,
    normalText: #1f2022,
    normalBackground: #e6ebef,
    normalBorder: #d5dbe0,
    hoverText: #404a53,
    hoverBackground: #cdd5de,
    hoverBorder: #bdc8d4,
  ),
);

/** Returns a named value from the $colors map. */
@function get-value($color, $tone) {
  @return map-get(map-get($colors, $color), $tone);
}

/** Injects the colors needed for either a regular row or a hovered row. */
@mixin get-colors($color, $type) {
  color: get-value($color, $type + 'Text');
  fill: get-value($color, $type + 'Text');
  background-color: get-value($color, $type + 'Background');
  border-color: get-value($color, $type + 'Border');
  z-index: get-value($color, 'priority') + if($type == 'hover', 100, 0);

  $inputText: get-value($color, $type + 'InputText');
  @if $inputText {
    .acp-table-cell-container .acp-input-text,
    .acp-table-cell-container .acp-input-dropdown select {
      color: $inputText;
    }
  }

  .acp-table-cell-container {
    color: get-value($color, $type + 'Text');

    .UserAvatar {
      margin-bottom: map_get($cellPadding, vertical);
    }
  }

  &:before {
    border-color: get-value($color, $type + 'Border');
  }
}

// The following code sets up all color variations, both for regular and for hovered/selected rows.
// The table cells are targeted in such a way that regular row colors only apply to main cells,
// subrow colors only apply to subrow cells, and individual cells can override both.
.acp-table {
  // Override for the rows containing the "no data" message.
  .acp-table-inner tbody {
    td.acp-table-nodata-block {
      border: 0;
    }
  }
  @each $color, $values in $colors {
    // Main row styling.
    tbody {
      // Small hack to allow the links inside to take up the full height.
      td {
        height: 0vh;
      }
      // Regular color for main cells.
      tr.acp-item.acp-color-#{'' + $color + ''} {
        .acp-ctarget.is-main-cell {
          @include get-colors($color, 'normal');
        }
      }
      // Hover/selected color for main cells.
      &:hover tr.acp-item.acp-color-#{'' + $color + ''},
      tr.acp-item.acp-selected.acp-color-#{'' + $color + ''} {
        .acp-ctarget.is-main-cell {
          @include get-colors($color, 'hover');
        }
      }
    }
    // Overrides for specific cells. We use an extra class for higher specificity.
    .acp-table-inner tbody {
      // Regular color for main cells.
      tr.acp-item {
        .acp-ctarget.acp-cell.is-main-cell.acp-color-#{'' + $color + ''} {
          @include get-colors($color, 'normal');
        }
      }
      // Hover/selected color for main cells.
      &:hover tr.acp-item,
      tr.acp-item:hover,
      tr.acp-item.acp-selected {
        .acp-ctarget.acp-cell.is-main-cell.acp-color-#{'' + $color + ''} {
          @include get-colors($color, 'hover');
        }
      }
    }
    // Subrow styling.
    tbody {
      // Regular color for subrow cells.
      tr.acp-item {
        .acp-ctarget.acp-cell.is-sub-cell.acp-color-#{'' + $color + ''} {
          @include get-colors($color, 'normal');
        }
      }
      // Hover/selected color for subrow cells.
      tr.acp-item:hover,
      tr.acp-item.acp-selected {
        .acp-ctarget.acp-cell.is-sub-cell.acp-color-#{'' + $color + ''} {
          @include get-colors($color, 'hover');
        }
      }
      // Selection for individual subrow cells (sub-selection).
      tr.acp-item {
        td.acp-cell.is-sub-cell.acp-color-#{'' + $color + ''}.acp-selected {
          @include get-colors($color, 'hover');
        }
      }
    }
  }
}
