.acp-base-icon {
  display: flex;
  height: 37px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.acp-base-icon > .img {
  margin: 0 auto 0 auto;
}
