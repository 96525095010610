.acp-fulfilment-audit-log-list {
  padding: 10px;
  max-height: 700px;
  overflow: scroll;
  max-width: 900px;
  background: '#f3f4f5';
}

.acp-fulfilment-audit-detail-message {
  white-space: initial;
  width: 180px;
}
