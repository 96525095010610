.App .entity-checkbox {
  height: 100%;
}
.App .entity-checkbox.data-boolean {
  text-align: left;
  display: inline-block;
}

.App .entity-checkbox label {
  height: 100%;
  cursor: pointer;
  margin: 0;
}
.App .entity-checkbox input {
  margin: 0;
  cursor: pointer;
}

.App .entity-checkbox.data-boolean.data-boolean-block {
  text-align: left;
  display: block;
}
.App .entity-checkbox.data-boolean.data-boolean-block label {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.App .entity-checkbox.data-boolean.data-boolean-block input {
  top: 0;
  margin-left: 0
}

.App .entity-checkbox.data-boolean.data-boolean-inline-center {
  text-align: left;
  display: inline-block;
  padding: 0 20px 0 0;
}
.App .entity-checkbox.data-boolean.data-boolean-inline-center label {
  padding: 5px 0;
  display: flex;
  align-items: center;
}
.App .entity-checkbox.data-boolean.data-boolean-inline-center input {
  top: 0;
  margin-left: 0
}
