.acp-table .simpletable tr .acp-table-cell-link {
  color: #1f2022;
}
.acp-table .simpletable tbody:hover tr .acp-table-cell-link {
  text-decoration: none;
}

.acp-table .simpletable tr .acp-table-cell-link.is-not-clickable {
  cursor: default;
}
.acp-table .simpletable tr .acp-table-cell-link.is-clickable {
  cursor: pointer;
}
