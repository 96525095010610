.App .dashboard-wrapper {
}
.App .dashboard-wrapper .top-section {
  background: white;
  border-bottom: 1px solid #ebe9ed;
  padding: 60px 0;
  margin-bottom: 60px;
}
.App .dashboard-wrapper .top-section h2 {
  margin: 0;
  color: #332f37;
  font-weight: 300;
}

.App.acp-theme-dark .dashboard-wrapper .top-section {
  background-color: #1c2938;
  border-color: #38444d;
}
.App.acp-theme-dark .dashboard-wrapper .top-section h2 {
  color: white;
}
