.App .acp-pagination {
  text-align: left;
}
.App .acp-pagination.inside-wrapper {
  border-top: 1px solid #e3e3e3;
  padding-left: 13px;
  padding-top: 13px;
}
.App .acp-pagination.withInfoColumn {
  margin-left: 30px;
}
