.entity-list-results {
  margin-bottom: 4px;
  margin-left: 2px;
  text-align: left;
}
.segment.footer .entity-list-results {
  margin-bottom: 0;
  margin-left: 0;
  text-align: left;
}
/* For e.g. shift bookings. */
.entity-detail-item .entity-list-results {
  border-bottom: 1px solid #e3e3e3;
  padding: 9px 11px;
  margin: 0;
}
