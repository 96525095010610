.App .editable-table .coordinate-input .icon {
  position: relative;
  top: 1px;
}
.App .editable-table .coordinate-input .coord {
  width: 180px;
  display: inline-block;
}
.App .editable-table .coordinate-input .coord:nth-child(3) {
  margin-left: -1px;
}

.App .editable-table .coordinate-input .coord:nth-child(2) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.App .editable-table .coordinate-input .coord:nth-child(3) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
