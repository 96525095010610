$colors: (
  'warning': (
    background: #f9c92e,
    borderTop: #e9b321,
    border: #e9b321,
    color: #784401,
  ),
  'danger': (
    background: #fddfdf,
    borderTop: #ec3d3d,
    border: rgba(125, 25, 25, 0.2),
    color: #412f2f,
  ),
  'success': (
    color: #155724,
    background: #d4edda,
    borderTop: #155724,
    border: #c3e6cb,
  ),
);

/** Returns a named value from the $colors map. */
@function get-value($color, $tone) {
  @return map-get(map-get($colors, $color), $tone);
}

.NotifyBar {
  background: white;
  border-radius: 3px;
  text-align: left;
  border: 1px solid gray;
  margin: 10px;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;

  &.no-margin {
    margin: 0 0 20px;
  }

  .inner {
    border-top: 2px solid transparent;
    padding: 10px 13px 11px;
    margin-top: -1px;
    border-radius: 2px;
  }

  @each $color, $values in $colors {
    &.type-#{"" + $color + ""} {
      background-color: get-value($color, 'background');
      border-color: get-value($color, 'border');
      color: get-value($color, 'color');

      .inner {
        border-top-color: get-value($color, 'borderTop');
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }
}
