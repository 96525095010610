.TimeInput {
  position: relative;
  cursor: text;
}
.TimeInput .clear {
  position: absolute;
  z-index: 5;
  top: 8px;
  right: 8px;
  width: 18px;
  height: 18px;
}
.TimeInput .clear:before {
  position: absolute;
  width: 18px;
  height: 18px;
  content: '×';
  font-size: 17px;
  text-align: center;
  border-radius: 50%;
  line-height: 16px;
  background-color: #c8c8c8;
  color: black;
  cursor: pointer;
}
.TimeInput .clear:hover:before {
  background-color: #aeaeae;
  color: black;
}
.TimeInput .field {
  min-width: 69px;
  border-radius: 2px;
  box-shadow: none;
  display: block;
  width: 100%;
  height: 35px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.TimeInput .field.focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.TimeInput .values {
  position: absolute;
  display: flex;
  font-size: 14px;
  line-height: 35px;
  height: 35px;
  color: #555;
}
.TimeInput .values .value {
  box-sizing: content-box;
}
.TimeInput .values .value.left {
  padding: 0 1px 0 14px;
}
.TimeInput .values .value.right {
  padding: 0;
}
.TimeInput .values .value .value-wrapper.active {
  background: #b6d7fe;
  outline: 1px solid #b6d7fe;
  color: #000;
}
.TimeInput .values .value.separator {
  padding-top: 0;
  width: 5px;
}

/* Disabled styling. */
.TimeInput.disabled {
  cursor: not-allowed;
}
.TimeInput.disabled .field {
  background: #eee;
}
