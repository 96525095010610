.App table.entity-list .entity-list-feedback .refetch {
  margin-top: 10px;
}
.App table.entity-list tr td.placeholder {
  text-align: center;
}
.App .entity-detail-item.with-entity-list table.entity-list tr td.placeholder .content-outer {
  height: 180px;
}
.App table.entity-list tr td.placeholder .content-outer {
  height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.App table.entity-list tr td.placeholder .content-inner {
}
.App table.entity-list tr td.placeholder .status {
  position: relative;
}
