.PhoneNumber {
  white-space: nowrap;
}
.PhoneNumber .inner.international {
  padding-right: 27px;
  position: relative;
}
.PhoneNumber .inner.international .type-label {
  position: absolute;
  right: 0;
}
