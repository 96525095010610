.App .RoleAndSkills .role,
.App .RoleAndSkills .skills,
#modals .RoleAndSkills .role,
#modals .RoleAndSkills .skills {
  display: inline-block;
}
.App .RoleAndSkills .skills,
#modals .RoleAndSkills .skills {
  display: block;
}
.App .RoleAndSkills .skills .role-and-skills-skill,
#modals .RoleAndSkills .skills .role-and-skills-skill {
  margin-right: 3px;
  padding: 0 6px 0 6px;
  display: inline-block;
  height: 15px;
  line-height: 15px;
  position: relative;
  border-radius: 3px;
  background: #777;
  font-weight: normal;
}
.App .RoleAndSkills .skills .role-and-skills-skill.is-special,
#modals .RoleAndSkills .skills .role-and-skills-skill.is-special {
  background: #4f99fa;
  font-weight: bold;
}
