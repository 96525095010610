.AlertsContainer .tab-content {
  height: 369px;
  overflow: auto;
}
.AlertsContainer .badge {
  vertical-align: text-bottom;
  background-color: #777;
  font-size: 10px;
  margin-left: 4px;
}
/* TODO: this hack is needed to fix the search bar, which otherwise overlaps the edge of the container.
 * A better and more stable UI solution is needed for this. */
.AlertsContainer .entity-detail-controls.overlayed {
  right: 36px;
}
.AlertsContainer .search-button {
  line-height: 20px !important;
  top: 0 !important;
  height: 32px;
}
