.App .acp-input.acp-input-text {
  background: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  min-width: 60px;
}
.App .acp-input.acp-input-text:disabled {
  background: #f3f4f5;
  cursor: not-allowed;
}
.App .acp-input-text-group {
  position: relative;
  display: table;
  border-collapse: separate;
}
.App .acp-input-text-group.is-full-width {
  width: 100%;
}
.App .acp-input-text-group.has-prefix .acp-input-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.App .acp-input-text-group.has-suffix .acp-input-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.App .acp-input-text-group .input-group-addon {
  background: #f3f4f5;
  border-color: #ccc;
  padding: 6px 9px;
}
.App .acp-input-text-group .input-group-addon:first-child {
}
.App .acp-input-text-group .input-group-addon:last-child {
}
