.FilterBoolCheckbox.type-white.checked {
  z-index: 10;
  background-color: #d2e5fe;
  box-shadow: inset 0 -1.5px 0 #aabed8, inset 0 0 0 1px #c3d6f1;
}
.FilterBoolCheckbox .checkbox {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  vertical-align: middle;
  position: relative;
  width: 20px;
  cursor: pointer;
}
.FilterBoolCheckbox .checkbox label {
  height: 18px;
  min-height: 18px;
  vertical-align: top;
  margin: 0;
  padding-right: 5px;
  padding-left: 0;
  width: 20px;
}
.FilterBoolCheckbox .checkbox label input {
  margin: 0;
  display: inline-block;
  cursor: pointer;
  position: relative;
  left: 0;
  right: 0;
  width: 15px;
  height: 15px;
}

.simple-checkbox {
  display: inline-block;
  vertical-align: middle;
}

.simple-checkbox-container {
  margin-top: 20px;
}
