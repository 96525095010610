.App {

  .entity-detail-item {
    .segment {
      border-top: 1px solid #e3e3e3;
      padding: 20px;

      &:first-child {
        border-top: none;
      }
    }
    .segment.with-table {
      padding: 0;
      >table.editable-table {
        >tbody {
          >tr {
            >th {
              width: 192px;
              vertical-align: top;
              line-height: 20px;
              padding: 8px 0 8px 20px;
            }
            >td {
              vertical-align: top;
              padding-top: 1px;
              &:last-child {
                padding-right: 20px;
              }
            }
            >td.field.middle {
              vertical-align: middle;
            }
            >th.field.middle {
              vertical-align: middle;
            }
          }
        }
        margin-bottom: 0;
      }
    }
    table.editable-table {
      &:last-child {
        >tbody {
          >tr {
            &:last-child {
              >td {
                border-bottom: none;
              }
              >th {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    .segment.no-padding {
      padding: 0;
    }
    .segment.footer {
      padding: 9px 11px;
    }
    .segment.no-border {
      border-top: 0;
    }
  }
}
.App.acp-theme-dark {
  .entity-detail-item {
    .segment {
      border-color: #38444d;
    }
  }
}
