.MuiPopover-paper.DatePickerCaption {
  max-width: 325px;
}
.DatePickerCaption .MuiToolbar-root.DatePickerCaptionToolbar {
  padding: 10px 15px;
  background-color: rgb(232, 244, 253);
}

.DatePickerCaption .MuiToolbar-root.DatePickerCaptionToolbar > *:not(:first-child) {
  padding-left: 10px;
}

.DatePickerCaption .MuiToolbar-root.DatePickerCaptionToolbar .MuiIcon-root {
  color: #2196f3;
}
