// Note: see <src/uikit/components/AcpTable/AcpTable.scss>.
$colors: (
  none: (
    normalBackground: #fff,
  ),
  red: (
    normalBackground: #ffeded,
  ),
  orange: (
    normalBackground: #fff6e4,
  ),
  yellow: (
    normalBackground: #ffffdb,
  ),
  green: (
    normalBackground: #e5ffde,
  ),
  cyan: (
    normalBackground: #e3f9ff,
  ),
  blue: (
    normalBackground: #dae8ff,
  ),
  purple: (
    normalBackground: #eee6fe,
  ),
  gray: (
    normalBackground: #e6ebef,
  ),
);

/** Returns a named value from the $colors map. */
@function get-value($color, $tone) {
  @return map-get(map-get($colors, $color), $tone);
}

.App .acp-menu-item {
  @each $color, $values in $colors {
    &.color-#{"" + $color + ""} > a {
      background-color: get-value($color, 'normalBackground');
    }
  }
}
