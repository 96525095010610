.App .entity-checkbox {
  position: relative;
}
.App .entity-checkbox.disabled label,
.App .entity-checkbox.disabled input,
.App .entity-checkbox.disabled {
  cursor: not-allowed;
}
.App .entity-checkbox label {
  padding: 9px 11px 9px 23px;
  cursor: pointer;
  margin: 0;
  font-weight: normal;
}
.App .entity-checkbox .header-label-checkbox {
  padding: 9px 0px 0px 10px;
}
.App .entity-checkbox label > span {
  padding-left: 0;
  padding-right: 6px;
  position: relative;
  left: 5px;
  /* Fixes some obscure rendering bug in Chrome. */
  border-left: 1px solid transparent;
}
.App .entity-checkbox label input {
  cursor: pointer;
  margin: 0 0 0 -12px;
  position: relative;
  top: -1px;
}
