.acp-cell-popover {
  float: left;
  width: 50px;
}

.acp-cell-popover-icon {
  position: absolute;
  margin-left: 45px;
  margin-top: 1px;
}

.acp-cell-popover-container {
  padding: 9px 11px;
  height: 100%;
}
