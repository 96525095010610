.DataTermDates {
  margin-bottom: 10px;
}

.DataTermDates .item {
  display: flex;
  margin-left: -24px;
}
.DataTermDates .item.will-destroy .subitem,
.DataTermDates .item.will-destroy .icon {
  opacity: 0.5;
}
.DataTermDates .item .subitem {
}
.DataTermDates .item .n {
  flex: 0 0 16px;
  line-height: 35px;
  text-align: left;
  margin: 0 8px 0 0;
}
.DataTermDates .item .arrow {
  padding: 0 10px;
}
.DataTermDates .item .icon {
  padding: 0 12px;
  /* Match the date bar in height. */
  height: 35px;
}
.DataTermDates .item .toggle {
  margin-left: 8px;
  width: 48.5px;
}

.DataTermDates .notice {
  font-size: smaller;
  line-height: 1.3em;
  margin: 0.5em 0;
}
