.data_tab_container {
  margin-right: 13px;
  background: none;
  border: none;
  border-bottom: solid 2px;
  text-align: left;
}

.data_tab_container_active {
  border-bottom: solid 3px #003a9b;
  color: #003a9b;
}

.data_tab_container_bold {
  font-weight: bold;
}

.data_tab_container_counter {
  margin-bottom: -15px;
}
