.DataDeletedRTWDocumentsList {
  display: table;
  width: 100%;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-top: 1em;

  .cell {
    display: table-cell;
    padding: 0.2em 0.5em;
    border: 1px solid #ccc;
    border-right: none;
    border-bottom: none;
  }

  .document-header {
    display: table-header-group;
    font-weight: bold;
  }

  .document {
    display: table-row;

    .cell {
      background-color: #f8f8f8;
      white-space: nowrap;
    }

    .doc-id {
      font-weight: bold;
    }

    .doc-image-link {
      white-space: normal;

      .image-link {
        white-space: nowrap;
      }
    }

    .not-available {
      opacity: 0.5;
    }
  }
}
