.App .acp-input-container .acp-input {
  display: block;
  margin: 0;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 35px;
  color: #555555;
  background: #fff;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.App .acp-input-container .acp-input:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
}
