.ListingShiftGroup {
  background: #f9f9fa;
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.ListingShiftGroup .inner {
  margin-left: -1px;
  margin-right: -1px;
}
.ListingShiftGroup .inner .group-row {
  margin-left: 0;
  margin-right: 0;
}
